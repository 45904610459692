// @flow
import { createSelector } from 'reselect'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

// selectors
import {
  getAlterations,
  getMasterData,
  getCreateQuote,
  getPolicyStructure,
  getIsAdviserPortal,
  getAlterationPolicies,
  getFeatureControlSwitch,
} from './common.selectors'
import { getIsAnyPolicyAltered } from './createQuote'
import { getAdvisor, getFeaturesEnabledForAdvisor } from './advisor'
import { getRulesForAlterationPolicies } from './altsDeclaration.selectors'

// utils
import { formatCurrency, getRelatedPartyNoBasedOnType, sortPolicies } from '../utils/quoteUtils'
import {
  convertBenefitCodeToFullName,
  getIsProtectionFirstPolicy,
  getAgentFromPolicies,
} from '../utils/policyUtils'
import { getCollectionFrequency } from '../utils/paymentUtils'
import { getAPEnabledFeatures, isFeatureEnabledForCP } from '../utils/commonUtils'
import {
  getIsMoreThanOneActiveBenefit,
  getIsChildOptimiserOnlyBenefitInPolicy,
  addSubBenefitInFeatures,
  noHangingChildCiCover,
  makeAltsProductsWaitingPeriods,
} from '../utils/alteration'
import { getLatterEscalationDate } from '../utils/alterationRules'
import { getCalQuoteErrorMessage } from '../utils/extendedQuoteUtils'
import { getLinkedPolicyInstanceNo } from '../utils/altsQuoteUtils'

// constants
import { getProductIdConfig } from '../constants/forms'
import {
  POLICY_BENEFIT_TISO,
  POLICY_BENEFIT_PREMIUM_WAIVER,
  POLICY_BENEFIT_SMB,
  INCOME_PROTECTION_RELATED_POLICY_BENEFITS,
  BUSINESS_EXPENSE_RELATED_POLICY_BENEFITS,
  POLICY_RELATIONSHIPS_LIFEASSURED,
  POLICY_STATE_INFORCE,
  LIFE_COVER_ID_LIST,
} from '../constants/policies'
import { IET } from '../constants/application'
import { BENEFIT_NATURE_TYPE_RIDER_OPTIMISER } from '../constants/benefit'
import { getEmail } from '../utils/contactUtils'
import {
  ALTERATION_TYPES,
  BENEFIT_ALTS_TYPE_REMOVE,
  PORTFOLIO_ANIVERSARY_PERIOD,
} from '../constants/alterations'
import { renderTextField } from '../utils/sitecoreUtils'
import { getModal } from './common'

const WHITESPACE = ' '

/**
 * Makes the life assured object by looking up the life assured object
 * within the policyStructures
 * and matching the quoteCustomerNo with the bancsCustomerNo of that object
 * @returns Object || undefined
 */
export const makeLifeInsuredForAlterationsFlow = createSelector(
  [getCreateQuote, getPolicyStructure],
  (createQuote, policyStructure) => {
    const { quoteCustomerNo } = createQuote || {}
    const policyStructureWithLifeAssured =
      policyStructure &&
      policyStructure.find(
        policy =>
          policy.relationships &&
          policy.relationships.find(relationship =>
            relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)
          )
      )

    return (
      policyStructureWithLifeAssured &&
      policyStructureWithLifeAssured.relationships.find(
        relationship =>
          relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED) &&
          getRelatedPartyNoBasedOnType(
            get(relationship, 'relatedParty.identifiers', []),
            'BANCS_CUSTOMER_NO'
          ) === quoteCustomerNo
      )
    )
  }
)

/**
 * Return the isFetchingPolicies from the alterations
 * @returns Boolean
 */
export const getIsFetchingPolicies = createSelector([getAlterations], alteration =>
  get(alteration, 'isFetchingPolicies', false)
)

// Helper to construct Sub Heading for Alterations flow
export const makeSubHeading = sitecoreField =>
  createSelector([makeLifeInsuredForAlterationsFlow], lifeInsured => {
    const firstName = get(lifeInsured, 'relatedParty.firstName', '')
    const lastName = get(lifeInsured, 'relatedParty.lastName', '')

    // Get party no within relatedParty.identifiers[type=PARTY_NO].value
    const identifiers = get(lifeInsured, 'relatedParty.identifiers', [])
    const partyNo = get(
      identifiers.find(identifier => identifier.type === 'PARTY_NO'),
      'value',
      ''
    )

    // Return empty when all parts of sub heading are empty
    return `${firstName}${lastName}${partyNo}`
      ? `${firstName}${WHITESPACE}${lastName}${WHITESPACE}|${WHITESPACE}${sitecoreField}${WHITESPACE}${partyNo}`
      : ''
  })

export const getEscalationRejectionDeadlineDate = (
  isAdviserPortal: boolean = true,
  dateFormat = 'MMMM DD, YYYY'
) =>
  createSelector([getRulesForAlterationPolicies], rules => {
    const rulesData = get(rules, 'businessData')

    const eligibleKey = isAdviserPortal
      ? 'rejectCPI_PolicyEligibleForAdviser'
      : 'rejectCPI_PolicyEligibleForCustomer'
    let deadlineDate
    get(rulesData, 'policies', []).forEach(policy => {
      const escalationRejectionDeadline = moment(
        get(policy, 'assesment.rejectCPI_EscalationRejectionDeadline'),
        'DD/MM/YYYY'
      )
      if (
        get(policy, `assesment.${eligibleKey}`) === 'Y' &&
        (!deadlineDate || escalationRejectionDeadline.isBefore(deadlineDate))
      ) {
        deadlineDate = escalationRejectionDeadline
      }
    })
    return deadlineDate ? moment(deadlineDate, 'DD/MM/YYYY').format(dateFormat) : ''
  })

// Get Benefits From Rules For Feature Removal
export const getBenefitsFromRulesForFeature = createSelector(
  [getRulesForAlterationPolicies],
  rules => get(rules, 'businessData.policies[0].policy.benefits', [])
)

export const makeRejectCPIRemainingDays = (isAdviserPortal: boolean = true) =>
  createSelector(
    [
      getEscalationRejectionDeadlineDate(isAdviserPortal, 'DD/MM/YYYY'),
      getRulesForAlterationPolicies,
    ],
    (deadlineDate, rules) => {
      const rulesBusinessData = get(rules, 'businessData')
      const today = moment(get(rulesBusinessData, 'assesment.systemInfo_TodaysDate'), 'DD/MM/YYYY')
      const escalationDeadLine = deadlineDate ? moment(deadlineDate, 'DD/MM/YYYY') : ''

      return escalationDeadLine ? escalationDeadLine.diff(today, 'days') + 1 : 0
    }
  )

// Get Benefit Period and Waiting Period From Rules
export const getWaitingPeriodAndBenefitPeriodEligliblity = createSelector(
  [
    getBenefitsFromRulesForFeature,
    (state, { type, benefitInstanceNo }) => ({
      type,
      benefitInstanceNo,
    }),
  ],
  (benefits = [], { type, benefitInstanceNo }) => {
    const filteredbenefit = benefits.find(
      benefit => benefit.type === type && +benefit.benefitInstanceNo === +benefitInstanceNo
    )
    const { waitingPeriodCanBeEdited, benefitPeriodCanBeEdited } = get(
      filteredbenefit,
      'assesment',
      {}
    )
    return {
      isBenefitPeriodDisabled: benefitPeriodCanBeEdited === 'N',
      isWaitingPeriodDisabled: waitingPeriodCanBeEdited === 'N',
    }
  }
)

// Construct the alteration type used for GA events tracking for
// e.g. Reject CPI for adviser portal and Customer Reject CPI for customer portal
// alteration type is picked from quote entity(createQuote)
export const getAlterationType = (altType: string = '') =>
  createSelector(
    [getMasterData, getIsAdviserPortal, getCreateQuote],
    (masterData, isAdviserPortal, createQuote) => {
      const alterationTypes = get(masterData, 'alterationTypes', [])
      const altsType = alterationTypes.find(
        ({ code }) => code === get(createQuote, 'alterationType', '')
      )
      return `${isAdviserPortal ? '' : 'Customer '}${altsType ? altsType.value : altType}`
    }
  )

/** Return the commencement date of a benefit by looking for a matching benefit in all policies
 * @param policyStructure list of all policies in quote
 * @param parentPolicyReferenceNo to match against policyStructure.policyInstanceNo
 * @param parentType to match against cover.type
 * @param parentBenefitInstanceNo to match against cover.benefitInstanceNo
 * @returns date in format yyyy-mm-dd format if found else ''
 */
export const getBenefitCommencementDate = (
  policyStructure: Array<Object>,
  parentPolicyReferenceNo: string,
  parentType: string,
  parentBenefitInstanceNo: string
): string => {
  const matchingPolicy = policyStructure.find(
    item => get(item, 'policyInstanceNo', '') === parseInt(parentPolicyReferenceNo, 10)
  )

  const matchingBenefit = get(matchingPolicy, 'covers', []).find(
    item =>
      get(item, 'type', '') === parentType &&
      get(item, 'benefitInstanceNo', '') === parentBenefitInstanceNo
  )
  return get(matchingBenefit, 'benefitCommencementDate', '')
}

/**
 * Find the policyStructure matching the parent benefit policy
 * and return the external policy number
 * @param parentPolicyReferenceNo the policyInstanceNo of parent benefit
 * @param policyStructure the entire policyStructure array
 * @returns policyNo of the matching policy within policyStructure array
 */
export const getExternalPolicyNo = (
  parentPolicyReferenceNo: string,
  policyStructure: Array<Object>
): string => {
  const matchingPolicy = policyStructure.find(
    eachPolicyStructure =>
      eachPolicyStructure.policyInstanceNo === parseInt(parentPolicyReferenceNo, 10)
  )
  return get(matchingPolicy, 'policyNo', '')
}

/**
 * Returns the Quote data object needed by Decrease cover quote tool
 */
export const getDecreaseCoverPolicyDetails = createSelector(
  getCreateQuote,
  getMasterData,
  getFeaturesEnabledForAdvisor,
  getRulesForAlterationPolicies,
  getAlterations,
  getIsAnyPolicyAltered,
  (state, fields) => fields,
  (
    createQuote,
    masterData,
    featuresEnabledForAdvisor,
    rules,
    alterations,
    isAnyPolicyAltered,
    fields
  ) => {
    const policyStructureArray = sortPolicies(get(createQuote, 'quotes[0].policyStructure', []))
    if (policyStructureArray.length > 0) {
      // changed policy can also be linked policy for optimiser benefit as we are also
      // setting calculatePolicyPrice true for linked policy in this case
      const changedPolicy = policyStructureArray.find(
        policy => policy.alteration?.calculatePolicyPrice
      )
      let linkedPolicyInstanceNo
      if (changedPolicy) {
        linkedPolicyInstanceNo = getLinkedPolicyInstanceNo(changedPolicy)
      }
      return policyStructureArray.map((eachPolicyStructure, i, policyStructure) => {
        const {
          alteration = {},
          productName,
          productId,
          productClass,
          covers,
          stampDuty = 0,
          policyFeeValue = 0,
          totalPremiumAmount,
          paymentFrequency,
          policyNo,
          bancsPolicyNo,
          policyInstanceNo,
          rebateAmount,
          policyCommencementDate,
        } = eachPolicyStructure
        const { policyType } = getProductIdConfig(productId)

        const isMoreThanOneActiveBenefit = getIsMoreThanOneActiveBenefit(
          covers,
          get(alteration, 'alteredBenefits')
        )

        const isProtectionFirstPolicy = getIsProtectionFirstPolicy(
          productId,
          masterData.productTypes
        )

        // creating message to display in banner for pre and within review period
        let bannerMessage = ''
        const selectedPolicyInRules = rules?.businessData?.policies.find(
          policy => policy.bancsPolicyNo === bancsPolicyNo
        )?.assesment
        const policyAnniversaryPeriod =
          selectedPolicyInRules?.decreaseIncrease_policyAnniversaryPeriod
        if (policyAnniversaryPeriod === PORTFOLIO_ANIVERSARY_PERIOD.PRE) {
          bannerMessage = get(fields, 'PrePolicyAnniversaryBannerMessage.value', '')
        } else if (policyAnniversaryPeriod === PORTFOLIO_ANIVERSARY_PERIOD.WITHIN) {
          bannerMessage = get(fields, 'WithinPolicyAnniversaryBannerMessage.value', '')
        }
        bannerMessage = bannerMessage.replace('##', selectedPolicyInRules?.rejectCPI_EscalationDate)

        // if error, show message for changed policy and linked policy
        const isPolicyErrorMessage =
          (alterations.altsCalculateQuoteError || alterations.isValidationError) &&
          (alteration?.calculatePolicyPrice ||
            String(linkedPolicyInstanceNo) === String(policyInstanceNo))
        // disable change in other policies
        const isPolicyChangeDisabled =
          isAnyPolicyAltered &&
          (alterations.altsCalculateQuoteError || alterations.isValidationError) &&
          !(
            alteration?.calculatePolicyPrice ||
            String(linkedPolicyInstanceNo) === String(policyInstanceNo)
          )

        return {
          alteration,
          policyType,
          policyNo,
          productId,
          stampDuty: formatCurrency(stampDuty),
          policyFee: formatCurrency(policyFeeValue),
          totalPremiumAmount,
          chipVariant: policyType === 'Inside Super' ? 'default' : 'important',
          productName,
          productClass,
          policyCommencementDate,
          collectionFrequency: getCollectionFrequency(paymentFrequency),
          ...(rebateAmount && { rebateAmount }),
          bancsPolicyNo,
          policyInstanceNo,
          bannerMessage,
          isPolicyErrorMessage,
          isPolicyChangeDisabled,
          covers: covers.map(cover => {
            const {
              name: benefitName,
              coverAmount,
              premiumAmount,
              premiumStyle,
              parentBenefitReference,
              childBenefitReferences,
              optimiserParentBenefitReference,
              features = [],
              type,
              waitingPeriod = {},
              coverPeriod = {},
              benefitAssured = [],
              benefitCommencementDate,
              discounts,
              loading = [],
              exclusion = [],
              benefitInstanceNo,
              tpdDefinition,
              subBenefits,
            } = cover

            const parentBenefitName =
              parentBenefitReference &&
              convertBenefitCodeToFullName(masterData, parentBenefitReference.parentType)
            const isIPorBEBenefit =
              INCOME_PROTECTION_RELATED_POLICY_BENEFITS.includes(type) ||
              BUSINESS_EXPENSE_RELATED_POLICY_BENEFITS.includes(type)
            const isOptionsVisible =
              features.some(feature => feature.featureApplicable === 'Y') || isIPorBEBenefit
            const hideSumInsured = type === POLICY_BENEFIT_PREMIUM_WAIVER
            const disableSumInsured =
              type === POLICY_BENEFIT_TISO ||
              type === POLICY_BENEFIT_SMB ||
              (parentBenefitReference &&
                parentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER)
            const isRemoveDisabled =
              !noHangingChildCiCover(cover, covers, alteration) ||
              !isMoreThanOneActiveBenefit ||
              (LIFE_COVER_ID_LIST.includes(type) && isProtectionFirstPolicy) ||
              (!!parentBenefitReference &&
                parentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER) ||
              getIsChildOptimiserOnlyBenefitInPolicy({
                policyStructureArray: policyStructure,
                coverToCompareWith: cover,
                policyInstanceNo,
              })

            const isCancelRemoveBenefitDisabled =
              parentBenefitReference &&
              parentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
            return {
              benefitName,
              type,
              tpdDefinition,
              benefitInstanceNo,
              benefitCommencementDate,
              optimiserParentBenefit: {
                name: convertBenefitCodeToFullName(
                  masterData,
                  get(optimiserParentBenefitReference, 'parentType', '')
                ),
              },
              coverAmount,
              premiumStyle,
              isRemoveDisabled,
              isCancelRemoveBenefitDisabled,
              isOptionsVisible,
              hideSumInsured,
              disableSumInsured,
              lifeInsuredFirstName: benefitAssured[0] ? benefitAssured[0].firstName : '',
              lifeInsuredLastName: benefitAssured[0] ? benefitAssured[0].lastName : '',
              isIPorBEBenefit,
              parentBenefitName,
              optimiserParentBenefitReference,
              applicableFeatures: addSubBenefitInFeatures(features, subBenefits),
              periods: {
                waitingPeriod: `${waitingPeriod.value || ''}`,
                waitingPeriodUnit: `${waitingPeriod.unit || ''}`,
                coverPeriod: `${coverPeriod.value || ''}`,
                coverPeriodUnit: `${coverPeriod.unit || ''}`,
              },
              premiumAmount,
              apEnabledFeatures: getAPEnabledFeatures(
                masterData.featureControlSwitch,
                [
                  'apDecreaseBenefitPeriod',
                  'apDecreaseSumInsured',
                  'apIncreaseWaitingPeriod',
                  'apRemoveBenefit',
                  'apRemoveBenefitOption',
                ],
                featuresEnabledForAdvisor
              ),
              discounts,
              loading,
              exclusion,
              ...(parentBenefitReference && {
                parentBenefit: {
                  name: convertBenefitCodeToFullName(masterData, parentBenefitReference.parentType),
                  commencementDate: getBenefitCommencementDate(
                    policyStructure,
                    parentBenefitReference.parentPolicyReferenceNo,
                    parentBenefitReference.parentType,
                    parentBenefitReference.parentBenefitInstanceNo
                  ),
                  policyNo:
                    policyInstanceNo ===
                    parseInt(parentBenefitReference.parentPolicyReferenceNo, 10)
                      ? ''
                      : getExternalPolicyNo(
                          parentBenefitReference.parentPolicyReferenceNo,
                          policyStructure
                        ),
                  parentPolicyReferenceNo: parentBenefitReference.parentPolicyReferenceNo,
                  parentType: parentBenefitReference.parentType,
                  parentBenefitInstanceNo: parentBenefitReference.parentBenefitInstanceNo,
                },
              }),
              ...(childBenefitReferences && {
                childBenefits: childBenefitReferences.map(
                  ({
                    benefitNature,
                    childType: childBenefitType,
                    childPolicyReferenceNo,
                    childBenefitInstanceNo,
                  }) => ({
                    benefitNature,
                    childBenefitType,
                    childPolicyReferenceNo,
                    childBenefitInstanceNo,
                  })
                ),
              }),
            }
          }),
        }
      })
    }
    return []
  }
)

export const getIsSMSF = createSelector([getPolicyStructure], policyStructure => {
  if (!policyStructure.length || !Array.isArray(policyStructure)) {
    return false
  }

  return policyStructure.some(policy =>
    policy.relationships
      ? policy.relationships.some(relationship => relationship.role.includes(IET))
      : false
  )
})

// TODO: Read relevant keys in rules
// Only RejectCPI keys are read by default. Returns [] for other usecases today.
/**
 * Use the Rules assessment and the Alteration policies
 * Return a set of alteration eligible policies
 */
export const getEligiblePolicies = (alterationType: string) =>
  createSelector(
    [getAlterationPolicies, getRulesForAlterationPolicies, getIsAdviserPortal],
    (alterationPolicies, rules, isAdviserPortal) => {
      let eligibilityKey

      switch (alterationType) {
        case ALTERATION_TYPES.DECREASE_RISK:
          eligibilityKey = isAdviserPortal
            ? 'decrease_EligibleForAdviser'
            : 'decrease_EligibleForCustomer'
          break
        default:
          eligibilityKey = isAdviserPortal
            ? 'rejectCPI_EligibleForAdviser'
            : 'rejectCPI_EligibleForCustomer'
      }
      if (get(rules, `businessData.assesment.${eligibilityKey}`, 'N') !== 'Y') return []

      const policiesListInRules = get(rules, 'businessData.policies', [])
      return alterationPolicies.filter(item => {
        const { bancsPolicyNo } = item

        const matchingRuleForPolicy =
          policiesListInRules.find(
            eachPolicyRule => eachPolicyRule.bancsPolicyNo === bancsPolicyNo
          ) || {}
        let policyEligibilityKey
        // default is kept as Reject CPI
        switch (alterationType) {
          case ALTERATION_TYPES.DECREASE_RISK: {
            policyEligibilityKey = isAdviserPortal
              ? 'decrease_PolicyEligibleForAdviser'
              : 'decrease_PolicyEligibleForCustomer'
            break
          }
          default: {
            policyEligibilityKey = isAdviserPortal
              ? 'rejectCPI_PolicyEligibleForAdviser'
              : 'rejectCPI_PolicyEligibleForCustomer'
          }
        }
        return get(matchingRuleForPolicy, `assesment.${policyEligibilityKey}`, 'Y') === 'Y'
      })
    }
  )

/**
 * Return the bancsCustomerNo from alterations.
 */
export const getBancsCustomerNoOfLAFromAlterations = createSelector([getAlterations], alterations =>
  get(alterations, 'bancsCustomerNo', '')
)

export const getUpdatePrintableAdviserDetailsPayload = createSelector(
  [getAdvisor, getIsAdviserPortal, getEligiblePolicies(ALTERATION_TYPES.DECREASE_RISK)],
  (advisor, isAdviserPortal, eligiblePolicies) => {
    const advisorDetails = isAdviserPortal
      ? advisor?.details
      : getAgentFromPolicies(eligiblePolicies)?.relatedParty
    return {
      firstName: advisorDetails?.firstName ?? '',
      lastName: advisorDetails?.lastName ?? '',
      businessName: advisorDetails?.businessName ?? '',
      phone: get(
        (advisorDetails?.contactMethods?.phones ?? []).filter(phone => phone.preferred === 'Y'),
        '[0].number',
        ''
      ),
      email: get(
        (advisorDetails?.contactMethods?.emails ?? []).filter(email => email.preferred === 'Y'),
        '[0].email',
        ''
      ),
      abn: advisorDetails?.abnNumber ?? '',
    }
  }
)

export const getAltsProductRules = createSelector(
  [getAlterations],
  alterations => alterations.productRules
)

export const getIsAltsEligible = createSelector([getRulesForAlterationPolicies], rules =>
  get(rules, 'businessData.policies', []).some(
    policy => get(policy, 'assesment.rejectCPI_InEscalationRejectionPeriod') === 'Y'
  )
)

/**
 * Return the portfolioAnniversaryPeriod from the given policy
 * @returns String
 */
export const getAnniversaryPeriod = createSelector([getRulesForAlterationPolicies], rules =>
  get(rules, 'businessData.assesment.decreaseIncrease_portfolioAnniversaryPeriod', '')
)

/**
 * Return the Escalation Date from the given policy
 * @returns Date
 */
export const getEscalationDate = (dateFormat = 'DD-MM-YYYY') =>
  createSelector([getRulesForAlterationPolicies, getAlterationPolicies], (rules, policies) => {
    const inforcePolicies = policies.map(
      policy =>
        get(policy, 'policy.status') === POLICY_STATE_INFORCE && get(policy, 'bancsPolicyNo')
    )
    const policy = get(rules, 'businessData.policies', []).filter(rulesPolicy =>
      inforcePolicies.includes(rulesPolicy.bancsPolicyNo)
    )
    const date = get(policy[0], 'assesment.rejectCPI_EscalationDate', '')
    return date ? moment(date, 'DD/MM/YYYY').format(dateFormat) : ''
  })

export const getAdvisorEmail = createSelector(
  [getAdvisor],
  advisor => getEmail(get(advisor, 'details.contactMethods.emails', [])) || ''
)

// check if we have all rules data as compared to minimal rules
export const getIsAllAltsRules = createSelector(
  [getRulesForAlterationPolicies],
  rules => !!get(rules, 'businessData.assesment')
)

export const getCurrentDate = createSelector([getRulesForAlterationPolicies], (rules = {}) =>
  get(rules, 'businessData.assesment.systemInfo_TodaysDate', '')
)

export const getAlterationTypeSelectedByUser = createSelector(
  [getAlterations],
  (alterations = {}) => get(alterations, 'alterationTypeSelectedByUser', '')
)

export const getAlterationTypeForAlterationsRoute = createSelector(
  [getAlterations],
  (alterations = {}) => get(alterations, 'alterationTypeForAlterationsRoute', '')
)

/**
 * Return the isPolicyAPIDoneForPolicyWithinAnivPeriod
 * @returns Boolean
 */
export const getIsPolicyAPIDoneForPolicyWithinAnivPeriod = createSelector(
  [getAlterations],
  (alterations = {}) => get(alterations, 'isPolicyAPIDoneForPolicyWithinAnivPeriod', false)
)

export const isDecreaseQuote = createSelector(
  [getCreateQuote],
  (createQuote = {}) => get(createQuote, 'alterationType', '') === ALTERATION_TYPES.DECREASE_RISK
)
export const getIsSubSectionCpHeader = (subheading: Object, ctaID: Object) =>
  createSelector([getAlterations, getFeatureControlSwitch], (alterations, featureControlSwitch) =>
    get(ctaID, 'value') === 'InflationProofing'
      ? isFeatureEnabledForCP(featureControlSwitch, 'altsEnableRejectCPI') &&
        !(get(alterations, 'rules.businessData.assesment.rejectCPI_EligibleForCustomer') === 'N') &&
        !!subheading
      : !!subheading
  )

export const getMembersContactDetails = createSelector([getAlterations], alterations =>
  get(alterations, 'membersContactDetails', {})
)

export const getAltsCalculateErrorModalDescription = (systemErrorLabel: { value: string }) =>
  createSelector([getAlterations], alterations =>
    get(alterations, 'altsCalculateQuoteError', renderTextField(systemErrorLabel))
  )

// Refer to https://github.com/reduxjs/reselect#q-how-do-i-create-a-selector-that-takes-an-argument
export const getAltsProductsWaitingPeriods = createSelector(
  [
    getAltsProductRules,
    (state, { type, periods, productId }) => ({
      type,
      periods,
      productId,
    }),
  ],
  (altsProductRules, { type, periods, productId }) =>
    makeAltsProductsWaitingPeriods(altsProductRules, { type, periods, productId })
)

export const isAllChildBenefitsRemoved = createSelector(
  [getPolicyStructure, (state, childBenefits) => childBenefits],
  (policies, childBenefits) => {
    if (!childBenefits || !childBenefits.length) return true
    return childBenefits.every(childBenefit =>
      policies.some(policy =>
        get(policy, 'alteration.alteredBenefits', []).some(
          alteredBenefit =>
            +childBenefit.childBenefitInstanceNo === +alteredBenefit.benefitInstanceNo &&
            childBenefit.childBenefitType === alteredBenefit.benefitCode &&
            alteredBenefit.benefitAlterationType === BENEFIT_ALTS_TYPE_REMOVE
        )
      )
    )
  }
)

export const getAltsCalculateErrorModalData = createSelector(
  [getAlterations, getMasterData],
  (alterations, masterData) => {
    const errorDescription = get(alterations, 'altsCalculateQuoteError.errorDescription')

    let errorModal

    if (isEmpty(errorDescription)) {
      // Default error message
      errorModal = {
        type: 'error',
        code: '',
        description: [],
      }
    } else {
      errorModal = {
        type: 'error',
        code: '105400',
        description: [errorDescription],
      }
    }
    return getCalQuoteErrorMessage(errorModal, masterData)
  }
)

export const getIsManualQuoteModal = createSelector(
  getAlterations,
  alterations => alterations.calculateFailureCount >= 3
)

/**
 * Return the isFetchingPolicies from the alterations
 * @returns Boolean
 */
export const getIsValidationError = createSelector([getAlterations], alteration =>
  get(alteration, 'isValidationError', false)
)

export const getAltsGenerateQuoteModal = createSelector([getModal], modal =>
  get(modal, 'altsGenerateQuoteTryAgainModal', {})
)

export const getIsGenerateQuoteModalVisible = createSelector(
  [getAltsGenerateQuoteModal],
  altsGenerateQuoteModal => get(altsGenerateQuoteModal, 'isModalVisible', false)
)

export const getPolicySelectionMap = createSelector(
  [getAlterations],
  alteration => alteration.policySelectionMap
)

export const getIsAltsPolicySelected = createSelector([getPolicySelectionMap], policySelectionMap =>
  Object.keys(policySelectionMap).some(policyNo => policySelectionMap[policyNo])
)

export const getWithInPeriodEffectiveDate = createSelector(
  [getRulesForAlterationPolicies, getAlterationPolicies],
  (rules, policies) => {
    const inforcePolicies = policies.map(
      policy =>
        get(policy, 'policy.status') === POLICY_STATE_INFORCE && get(policy, 'bancsPolicyNo')
    )
    const rulesInforcePolicies = get(rules, 'businessData.policies', []).filter(rulesPolicy =>
      inforcePolicies.includes(rulesPolicy.bancsPolicyNo)
    )
    const portfolioRules = rules?.businessData?.assesment
    if (
      portfolioRules?.decreaseIncrease_portfolioAnniversaryPeriod ===
      PORTFOLIO_ANIVERSARY_PERIOD.WITHIN
    ) {
      if (portfolioRules?.decreaseIncrease_AnniversaryDateConflictForAdviser === 'N') {
        const date = rulesInforcePolicies[0].assesment?.rejectCPI_EscalationDate
        return date ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
      }
      return getLatterEscalationDate(rulesInforcePolicies)
    }
    if (
      portfolioRules?.decreaseIncrease_portfolioAnniversaryPeriod ===
      PORTFOLIO_ANIVERSARY_PERIOD.MULTIPLE
    ) {
      // filtering within policies and then finding latter date among them
      const withInPolicies = rulesInforcePolicies.filter(
        policy =>
          policy.assesment?.decreaseIncrease_policyAnniversaryPeriod ===
          PORTFOLIO_ANIVERSARY_PERIOD.WITHIN
      )
      return getLatterEscalationDate(withInPolicies)
    }
    return ''
  }
)

// To disable page actions like Continue and Download for decrease when there is error
export const getIsPageActionsDisabled = createSelector(
  [getAlterations, getIsAnyPolicyAltered],
  (alterations, isAnyPolicyAltered) =>
    (alterations.altsCalculateQuoteError || alterations.isValidationError) && isAnyPolicyAltered
)
