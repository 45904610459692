// @flow
import React, { type Node, Component } from 'react'
import styled from '@emotion/styled'
import { withRouter } from 'react-router-dom'
import { pathOr } from 'lodash/fp'
import { get } from 'lodash'
import debounce from 'lodash/debounce'
import BurgerMenu from '@mlcl-digital/mlcl-design/lib/base/BurgerMenu'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// atoms.
import Logo from '../../../../atoms/MLCAcendaLogo'
import NavItem from '../../../../atoms/NavItem'

// styles.
import styles from './quoteNavigation.styles'
import { themeConsumer } from '../../../../../styles/ThemeContext'

// utils.
import { renderTextField } from '../../../../../utils/sitecoreUtils'

type QuoteNavigationProps = {
  // actions object
  actions: {
    updateIsSaveQuote: Function,
    showSaveQuoteModal: Function,
    updateSaveQuoteModalDisplay: Function,
    saveQuote: Function,
    openSidebar: Function,
    isUpdatedQuote: Function,
    initiateSaveQuote: Function,
  },
  // fields from sitecore
  fields: Object,
}

type QuoteNavigationState = {
  // Menu state for mobile
  menuOpen: boolean,
  // Sets scrolling state for the header
  isScrolling: boolean,
}

const Header = styled('header')(styles.header)
const LogoContainer = styled('div')(styles.logoContainer)
const Nav = styled('nav')(styles.nav)
const List = styled('ul')(styles.list)
const Item = styled('div')(styles.item)
const ItemLink = styled(Item.withComponent(NavItem))(styles.itemLink)

class QuoteNavigation extends Component<QuoteNavigationProps, QuoteNavigationState> {
  constructor() {
    super()

    this.state = {
      menuOpen: false,
      isScrolling: false,
    }
    this.handleScroll = debounce(this.handleScroll.bind(this), 5)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    this.handleScroll.cancel()
  }

  setScrollingState(isScrolling) {
    this.setState(prevState => ({
      ...prevState,
      isScrolling,
    }))
  }

  handleScroll = () => {
    const { isScrolling } = this.state

    if (window.scrollY === 0 && isScrolling === true) {
      this.setScrollingState(false)
    } else if (window.scrollY !== 0 && isScrolling !== true) {
      this.setScrollingState(true)
    }
  }

  handleOnClickMenu = () => {
    this.setState(prevState => ({
      ...prevState,
      menuOpen: !prevState.menuOpen,
    }))
  }

  /**
   * Handler for Save CTA
   */
  saveQuoteHandler = () => {
    const {
      actions: { initiateSaveQuote },
    } = this.props
    initiateSaveQuote()
  }

  // delegate click handlers with onClick props from quoteNavigation.data.js.
  // NOTE: calling computed functions (e.g. `this[handlerName]`) will throw a flow error.
  executeClick = (id): string => {
    switch (id) {
      case 'save':
        this.saveQuoteHandler()
        break
      case 'lifeInsured':
        this.viewLifeInsured()
        break
      case 'preAssessment':
        this.handlePreAssessment()
        break
      case 'projections':
        this.openProjections()
        break
      case 'visualiser':
        this.openVisualiser()
        break
      default:
        break
    }
  }

  onExitToDashboard = event => {
    const {
      history,
      actions: { showSaveQuoteModal: updateSaveQuoteModalDisplay, isIPCoverCalculated },
    } = this.props
    event.preventDefault()
    const newRouteUrl = pathOr('/', 'location.pathname', history)

    // Redirect on the basis of route
    switch (newRouteUrl) {
      case '/quote-tool':
        updateSaveQuoteModalDisplay(true, true, 'redirectToDashboard')
        break
      default:
        history.push('/dashboard')
    }
    isIPCoverCalculated(false)
  }

  viewLifeInsured = () => {
    const {
      actions: { openSidebar },
    } = this.props
    openSidebar('lifeInsured', 0, { fields: {} }, 0)
  }

  handlePreAssessment = () => {
    const {
      actions: { openSidebar, setPreAssessmentOpenMode },
    } = this.props

    openSidebar('preAssessment')
    setPreAssessmentOpenMode('default')
  }

  /**
   * opens Projections in Sidebar.
   */
  openProjections = (): void => {
    const {
      actions: { openSidebar, initiateSaveQuote },
    } = this.props
    initiateSaveQuote(() => openSidebar('projections'), 'projections')
  }

  /**
   * opens Visualiser in Sidebar.
   */
  openVisualiser = (): void => {
    const {
      actions: { openSidebar, initiateSaveQuote },
    } = this.props
    initiateSaveQuote(() => openSidebar('visualiser'), 'visualiser')
  }

  render() {
    const { menuOpen, isScrolling } = this.state
    const { fields } = this.props
    const { logoHorizontal, logoVertical, primary } = fields

    const fauxFeilds = {
      exitText: 'Exit to dashboard',
    }

    const { exitText } = fauxFeilds

    return (
      <Header isScrolling={isScrolling}>
        <LogoContainer>
          <Logo
            href=""
            horizontalSrc={get(logoHorizontal, 'value', '')}
            verticalSrc={get(logoVertical, 'value', '')}
            alt="MLC"
          />
          <BurgerMenu isOpen={menuOpen} onClick={this.handleOnClickMenu} />
        </LogoContainer>

        <Nav isOpen={menuOpen}>
          <List>
            {primary.map((nav: Object): Node => (
              <ItemLink
                key={get(nav, 'fields.text.value', '')}
                {...(get(nav, 'fields.href.value', '')
                  ? { href: get(nav, 'fields.href.value', '') }
                  : {})}
                onClick={() => this.executeClick(get(nav, 'fields.id.value', ''))}
              >
                {renderTextField(get(nav, 'fields.text', ''))}
              </ItemLink>
            ))}
            <Item>
              <ItemLink onClick={this.onExitToDashboard}>{exitText}</ItemLink>
            </Item>
          </List>
        </Nav>
      </Header>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

const withRouterNavigate = withRouter(QuoteNavigation)

export default connect(null, mapDispatchToProps)(themeConsumer(withRouterNavigate, 'navigation'))
