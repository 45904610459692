// @flow
import React, { type Node, Component } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import BurgerMenu from '@mlcl-digital/mlcl-design/lib/base/BurgerMenu'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// components.
import Button from '../../../../atoms/Button'
import Logo from '../../../../atoms/MLCAcendaLogo'
import NavItem from '../../../../atoms/NavItem'

// styles.
import { deviceBreakpoints } from '../../../../../styles'
import styles from './applicationNavigation.styles'
import { themeConsumer } from '../../../../../styles/ThemeContext'

import { renderTextField } from '../../../../../utils/sitecoreUtils'

// constants
import { APPLICATION_OVERVIEW_ROUTE } from '../../../../../constants/routes'

type navigationProps = {
  actions: {
    openSidebar: Function,
    initiateSaveQuote: Function,
  },
  fields: Object,
  navigation: Object,
  sidebar: Object,
}

type navigationState = {
  // Sets the dropdown compoment toggle to open
  dropdown: Object,
  // Sets scrolling state for the header
  header: {
    isScrolling: boolean,
  },
  isMobileView: boolean,
}

const Header = styled('header')(styles.header)
const LogoContainer = styled('div')(styles.logoContainer)
const Nav = styled('nav')(styles.nav)

const List = styled('ul')(({ theme }) => styles.list(theme, NavItem))
const Item = styled('li')(({ theme }) => styles.item(theme))
const ItemLink = styled(Item.withComponent(NavItem))(styles.itemLink)

const NavSubSection = styled('div')(styles.navSubSection)

const QuickAction = styled(Item.withComponent('li'))(styles.quickAction)
const QuickActionButton = styled(Button)(styles.quickActionButton)

const ExitBtn = styled(Item.withComponent(NavItem))(styles.exitToDashboard)

export class ApplicationNavigation extends Component<navigationProps, navigationState> {
  constructor() {
    super()

    this.state = {
      header: {
        isScrolling: false,
      },
      isMobileView: false,
    }

    this.handleOnClickMenu = this.handleOnClickMenu.bind(this)
    this.handleNavItemClick = this.handleNavItemClick.bind(this)
    this.handleScroll = debounce(this.handleScroll.bind(this), 5)
    this.validateMobileView = debounce(this.validateMobileView.bind(this), 5)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.validateMobileView)
    this.validateMobileView()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.validateMobileView)
    this.handleScroll.cancel()
    this.validateMobileView.cancel()
  }

  setScrollingState(isScrolling) {
    this.setState(prevState => ({
      header: {
        ...prevState.header,
        isScrolling,
      },
    }))
  }

  saveQuoteHandler = () => {
    const {
      actions: { initiateSaveQuote },
    } = this.props
    initiateSaveQuote()
  }

  handleNavItemClick = (event: Object, navId: string) => {
    switch (navId) {
      case 'save': {
        event.preventDefault()
        this.saveQuoteHandler()
        break
      }
      default:
    }
  }

  handleOnClickMenu() {
    const {
      actions: { toggleBurgerMenu },
      navigation: { isOpen: menuIsOpen },
    } = this.props

    toggleBurgerMenu(!menuIsOpen)
  }

  handleScroll() {
    const {
      header: { isScrolling },
    } = this.state

    if (window.scrollY === 0 && isScrolling === true) {
      this.setScrollingState(false)
    } else if (window.scrollY !== 0 && isScrolling !== true) {
      this.setScrollingState(true)
    }
  }

  validateMobileView() {
    if (document.body.clientWidth < deviceBreakpoints.md) {
      this.setState({ isMobileView: true })
    } else {
      this.setState({ isMobileView: false })
    }
  }

  render() {
    const {
      header: { isScrolling },
      isMobileView,
    } = this.state

    const {
      actions,
      fields,
      navigation: { isOpen: menuIsOpen },
      sidebar,
    } = this.props
    const { logoHorizontal, logoVertical, primary, exitToDashboard, viewQuoteLink } = fields
    const { openSidebar } = actions

    return (
      <Header
        data-testid="overview-navigation"
        isScrolling={isScrolling}
        isSidebarOpen={sidebar && sidebar.open}
      >
        <LogoContainer>
          <Logo
            href={APPLICATION_OVERVIEW_ROUTE}
            horizontalSrc={get(logoHorizontal, 'value', '')}
            verticalSrc={get(logoVertical, 'value', '')}
            alt="MLC"
          />
          <BurgerMenu isOpen={menuIsOpen} onClick={this.handleOnClickMenu} />
        </LogoContainer>
        <Nav isOpen={menuIsOpen}>
          <List>
            {isMobileView &&
              primary.map((nav: Object): Node => (
                <ItemLink
                  key={get(nav, 'fields.text.value', '')}
                  href={get(nav, 'fields.href.value', '')}
                >
                  {renderTextField(get(nav, 'fields.text', ''))}
                </ItemLink>
              ))}
          </List>
        </Nav>
        <NavSubSection>
          <List>
            {primary &&
              primary.map((nav: Object): Node => (
                <ItemLink
                  key={get(nav, 'fields.text.value', '')}
                  href={get(nav, 'fields.href.value', '')}
                  onClick={event => this.handleNavItemClick(event, get(nav, 'fields.id.value', ''))}
                >
                  {renderTextField(get(nav, 'fields.text', ''))}
                </ItemLink>
              ))}
            {viewQuoteLink && (
              <QuickAction>
                <QuickActionButton
                  type="secondary"
                  onClick={() => openSidebar('quoteTool', 0, { fields }, 0)}
                >
                  {renderTextField(get(viewQuoteLink, 'fields.text', ''))}
                </QuickActionButton>
              </QuickAction>
            )}

            {exitToDashboard && (
              <ExitBtn href={get(exitToDashboard, 'fields.href.value', '')}>
                {renderTextField(get(exitToDashboard, 'fields.text', ''))}
              </ExitBtn>
            )}
          </List>
        </NavSubSection>
      </Header>
    )
  }
}

const mapStateToProps = ({ navigation, sidebar }) => ({
  navigation,
  sidebar,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(themeConsumer(ApplicationNavigation, 'navigation'))
