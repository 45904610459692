// @flow
import get from 'lodash/get'
import {
  ALTERATION_POLICIES_FETCH,
  ALTERATION_POLICIES_FETCH_INIT,
  ALTERATION_POLICIES_FETCH_SUCCESS,
  ALTERATION_POLICIES_FETCH_ERROR,
  ALTERATION_POLICIES_FETCH_FAILURE,
  MEMBER_CONTACT_DETAILS_UPDATE,
  MEMBER_CONTACT_DETAILS_UPDATE_INIT,
  MEMBER_CONTACT_DETAILS_UPDATE_SUCCESS,
  MEMBER_CONTACT_DETAILS_UPDATE_FAILURE,
  MEMBER_CONTACT_DETAILS_UPDATE_ERROR,
  MEMBER_CONTACT_DETAILS_FORM_RESET,
  GET_ESCALATION_INIT,
  GET_ESCALATION_SUCCESS,
  GET_ESCALATION_ERROR,
  GET_ESCALATION_FAILURE,
  GET_PRODUCT_RULES_INIT,
  GET_PRODUCT_RULES_SUCCESS,
  GET_PRODUCT_RULES_ERROR,
  GET_PRODUCT_RULES_FAILURE,
  GET_ALTERATIONS_RULES_INIT,
  GET_ALTERATIONS_RULES_ERROR,
  GET_ALTERATIONS_RULES_FAILURE,
  GET_ALTERATIONS_RULES_SUCCESS,
  SET_ALTERATIONS_POLICIES,
  SET_IS_ESCALATION_API_DONE,
  SET_USER_ALTERATIONTYPE_SELECTION,
  SET_USER_ALTERATIONTYPE_SELECTION_ALTERATIONSROUTE,
  RESET_ALTERATIONS,
  SET_IS_POLICY_API_DONE_FOR_POLICY_WITHIN_ANIV_PERIOD,
  SET_IS_ALTS_CALCULATE_ERROR_MODAL,
  RESET_ALTS_CALCULATE_FAILURE_COUNT,
  VALIDATE_QUOTE_INIT,
  VALIDATE_QUOTE_SUCCESS,
  VALIDATE_QUOTE_ERROR,
  VALIDATE_QUOTE_FAILURE,
  SET_IS_VALIDATION_ERROR,
  RESET_ALTS_PRODUCT_RULES,
  RESET_ALTS_PRODUCT_RULES_FAILED,
  SHOW_ALTS_INCREASED_PREMIUM_MODAL,
  SET_OR_TOGGLE_POLICYSELECTION_KEY,
  SELECT_LIST_OF_POLICIES,
  DESELECT_ALL_POLICIES,
  RESET_ALTERATIONS_QUOTE_ERROR,
  SET_SUM_INSURED_ERRORS,
  RESET_SUM_INSURED_ERRORS,
  SET_IS_CONFIRM_LEAVE_MODAL,
  SET_IS_ALTS_QUOTE_DOWNLOADED,
  SET_IS_ALTS_QUOTE_URN,
  SET_IS_ALTS_QUOTE_SUBMITTED,
  SET_IS_SUBMISSION_IN_PROGRESS,
  SET_IS_MAX_DOWNLOAD_LIMIT_MODAL,
  SET_ALTS_QUOTE_SUBMITTED_POLICIES,
} from '../actions/types/alterations'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'
import {
  ALTS_CALCULATE_QUOTE_INIT,
  ALTS_CALCULATE_QUOTE_SUCCESS,
  ALTS_CALCULATE_QUOTE_FAILURE,
  ALTS_CALCULATE_QUOTE_ERROR,
  DELETE_ALTERATIONS_IN_QUOTE,
  ALTER_SUM_INSURED,
  RESET_SUM_INSURED,
  REMOVE_BENEFIT_OPTION,
  CANCEL_BENEFIT_OPTION,
  CHANGE_WAITING_BENEFIT_PERIOD,
  RESET_WAITING_BENEFIT_PERIOD,
  ALTS_REMOVE_BENEFIT,
  ALTER_PAYMENT_FREQUENCY,
  RESET_PAYMENT_FREQUENCY,
} from '../actions/types/createQuote'

export const initialState = {
  isFetchingRejectCPI: false,
  // rejectCPIData: null,
  rejectCPIError: null,
  // policies data
  policies: [],
  // If fetching policies in progress
  isFetchingPolicies: false,
  // Is error in fetching policies
  isPoliciesFetchError: false,
  // client id
  clientId: '',
  // Members contact details with key as bancsCustomerNo
  membersContactDetails: {},
  // Object container for all Member contact details form state
  memberContactDetailsForm: {
    hasNetworkError: false,
    hasFormSubmitted: false,
  },
  // number of escalation API calls for different policies
  escalationAPICount: null,
  // placeholder for product rules data
  productRules: [],
  // if fetching alteration rules
  isFetchingRules: false,
  // alteration rules
  rules: null,
  // to decide whether to make escalation API call or not on
  // policy anniversary page in reject cpi tile component
  isEscalationAPIDone: false,
  // controls whether alteration landing page is accessible directly
  alterationTypeSelectedByUser: '',
  // controls whether alterations screens are accessible directly
  alterationTypeForAlterationsRoute: '',
  // Checks whether API call done for policy within aniv period for the selected client
  isPolicyAPIDoneForPolicyWithinAnivPeriod: false,
  // calculate quote API status
  isFetchingCalculateQuote: false,
  // error details for alteration calculate quote API
  altsCalculateQuoteError: null,
  // to show/hide modal for error in calculating premium
  isAltsCalculateErrorModal: false,
  // Rules api error details
  rulesError: null,
  // number of consecutive calculate API failures
  calculateFailureCount: 0,
  // quote validation api progress
  isFetchingValidation: false,
  // quote validation data
  quoteValidationError: null,
  // Frontend Validation Errors
  isValidationError: false,
  // number of api calls in progress
  productRulesAPICount: 0,
  // list of product IDs for which product rules API have failed
  productRulesFailedAPIs: [],
  // to show/hide increase premium modal for decrease application
  isIncreasedPremiumModal: false,
  // increase premium value for decrease application to be used in modal
  increasedPremium: 0,
  // map used to store whether a policy is selected for alteration
  policySelectionMap: {},
  // list for saving submitted policies during the session
  policiesSubmitted: [],
  // error collection for changed sum insured fields
  sumInsuredErrors: {},
  // show/hide confirm page leave modal for premium calculator
  isConfirmLeaveModal: false,
  // redirect url when user confirms in leave modal
  confirmLeaveModalRedirectURL: '',
  // item id for element which was clicked that resulted in opening confirm leave modal
  confirmLeaveModalItemID: '',
  // tells if quote is downloaded after making alteration
  isAltsQuoteDownloaded: false,
  // part of the downloaded quote pdf filename
  quoteURN: '',
  // tells if quote is submitted after making alteration
  isAltsQuoteSubmitted: false,
  // if submission is progressing
  isSubmissionInProgress: false,
  // show/hide max download limit modal for Cover Manager
  isMaxDownloadLimitModal: false,
}

const alteration = (state: Object = initialState, { type, payload }: Object) => {
  switch (type) {
    case ALTERATION_POLICIES_FETCH:
    case ALTERATION_POLICIES_FETCH_INIT: {
      return {
        ...state,
        isFetchingPolicies: true,
        isPoliciesFetchError: false,
      }
    }
    case ALTERATION_POLICIES_FETCH_SUCCESS: {
      return {
        ...state,
        // filtering out those policies for which policy object is not present.
        // this is because the policies don't belong to the user.
        policies: get(payload, 'policies', []).filter(policyData => policyData.policy),
        clientId: payload.clientId,
        bancsCustomerNo: payload.bancsCustomerNo,
        isFetchingPolicies: false,
      }
    }
    case ALTERATION_POLICIES_FETCH_ERROR:
    case ALTERATION_POLICIES_FETCH_FAILURE: {
      return {
        ...state,
        policies: [],
        isFetchingPolicies: false,
        isPoliciesFetchError: true,
      }
    }
    case SET_ALTERATIONS_POLICIES: {
      return {
        ...state,
        // filtering out those policiesx for which policy object is not present
        policies: get(payload, 'policies', []).filter(policyData => policyData.policy),
        clientId: payload.bancsCustomerNo,
        bancsCustomerNo: payload.bancsCustomerNo,
      }
    }
    case MEMBER_CONTACT_DETAILS_FORM_RESET: {
      return {
        ...state,
        memberContactDetailsForm: { ...initialState.memberContactDetailsForm },
      }
    }
    case MEMBER_CONTACT_DETAILS_UPDATE:
    case MEMBER_CONTACT_DETAILS_UPDATE_INIT: {
      const { body } = payload
      const { bancsCustomerNo } = JSON.parse(body)
      return {
        ...state,
        membersContactDetails: {
          [bancsCustomerNo]: { isUpdateLoading: true, hasUpdateError: false },
        },
      }
    }
    case MEMBER_CONTACT_DETAILS_UPDATE_SUCCESS: {
      const {
        bancsCustomerNo,
        memberDetails: { contactMethods },
      } = payload
      const { membersContactDetails } = state
      return {
        ...state,
        membersContactDetails: {
          ...membersContactDetails,
          [bancsCustomerNo]: { isUpdateLoading: false, hasUpdateError: false, contactMethods },
        },
      }
    }
    case MEMBER_CONTACT_DETAILS_UPDATE_ERROR: {
      const { memberContactDetailsForm } = state
      return {
        ...state,
        memberContactDetailsForm: {
          ...memberContactDetailsForm,
          hasNetworkError: true,
        },
      }
    }
    case MEMBER_CONTACT_DETAILS_UPDATE_FAILURE: {
      const { bancsCustomerNo } = payload
      const { membersContactDetails } = state
      return {
        ...state,
        membersContactDetails: {
          ...membersContactDetails,
          [bancsCustomerNo]: { isUpdateLoading: false, hasUpdateError: true },
        },
      }
    }
    case GET_ESCALATION_INIT: {
      return {
        ...state,
        escalationAPICount: (state.escalationAPICount || 0) + 1,
      }
    }
    case GET_ESCALATION_SUCCESS: {
      return {
        ...state,
        escalationAPICount: state.escalationAPICount - 1,
        policies: state.policies.map(policy => {
          if (policy.bancsPolicyNo === get(payload, 'businessData.bancsPolicyNumber')) {
            return {
              ...policy,
              escalation: payload.businessData,
            }
          }
          return policy
        }),
      }
    }
    case GET_ESCALATION_ERROR:
    case GET_ESCALATION_FAILURE: {
      return {
        ...state,
        escalationAPICount: state.escalationAPICount - 1,
      }
    }

    case RESET_ALTS_PRODUCT_RULES: {
      return {
        ...state,
        productRulesAPICount: 0,
        productRulesFailedAPIs: [],
      }
    }

    case RESET_ALTS_PRODUCT_RULES_FAILED: {
      return {
        ...state,
        productRulesFailedAPIs: [],
      }
    }

    case GET_PRODUCT_RULES_INIT: {
      return {
        ...state,
        productRulesAPICount: state.productRulesAPICount + 1,
      }
    }

    case GET_PRODUCT_RULES_SUCCESS: {
      const {
        businessData: { productRule },
      } = payload
      const { productRules = [] } = state
      return {
        ...state,
        productRules: [...productRules, productRule.product],
        productRulesAPICount: state.productRulesAPICount - 1,
      }
    }
    case GET_PRODUCT_RULES_ERROR:
    case GET_PRODUCT_RULES_FAILURE: {
      const { productId } = payload
      return {
        ...state,
        productRulesAPICount: state.productRulesAPICount - 1,
        productRulesFailedAPIs: state.productRulesFailedAPIs
          .filter(item => item !== productId)
          .concat([productId]),
      }
    }
    case GET_ALTERATIONS_RULES_INIT: {
      return {
        ...state,
        isFetchingRules: true,
      }
    }
    case GET_ALTERATIONS_RULES_SUCCESS: {
      return {
        ...state,
        isFetchingRules: false,
        rules: payload,
        rulesError: null,
      }
    }
    case GET_ALTERATIONS_RULES_ERROR:
    case GET_ALTERATIONS_RULES_FAILURE: {
      return {
        ...state,
        isFetchingRules: false,
        rulesError: payload,
      }
    }
    case SET_IS_ESCALATION_API_DONE: {
      return {
        ...state,
        isEscalationAPIDone: true,
      }
    }
    case SET_IS_POLICY_API_DONE_FOR_POLICY_WITHIN_ANIV_PERIOD: {
      return {
        ...state,
        isPolicyAPIDoneForPolicyWithinAnivPeriod: true,
      }
    }
    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }
    case SET_USER_ALTERATIONTYPE_SELECTION: {
      return {
        ...state,
        alterationTypeSelectedByUser: payload.alterationTypeSelectedByUser,
      }
    }
    case SET_USER_ALTERATIONTYPE_SELECTION_ALTERATIONSROUTE: {
      return {
        ...state,
        alterationTypeForAlterationsRoute: payload.alterationType,
      }
    }
    case ALTS_CALCULATE_QUOTE_INIT: {
      return {
        ...state,
        isFetchingCalculateQuote: true,
      }
    }
    case ALTS_CALCULATE_QUOTE_SUCCESS: {
      const { businessData, activeIndex } = payload
      const increasedPremium = get(
        businessData,
        `quotes[${activeIndex}].policyStructure`,
        []
      ).reduce((accumulator, policy) => {
        const saving = get(policy, 'alteration.saving', 0)
        // premium increase modal will be shown if there is negative savings for any policy,
        // even if overall saving is positive in the quote
        if (saving < 0) {
          return accumulator + Math.abs(saving)
        }
        return accumulator
      }, 0)
      return {
        ...state,
        isFetchingCalculateQuote: false,
        isAltsCalculateErrorModal: false,
        altsCalculateQuoteError: null,
        calculateFailureCount: 0,
        isIncreasedPremiumModal: increasedPremium > 0,
        increasedPremium,
      }
    }
    case ALTS_CALCULATE_QUOTE_FAILURE:
    case ALTS_CALCULATE_QUOTE_ERROR: {
      const { errorCode } = payload
      return {
        ...state,
        isFetchingCalculateQuote: false,
        altsCalculateQuoteError: payload,
        isAltsCalculateErrorModal: true,
        ...(errorCode === 400 && {
          calculateFailureCount: state.calculateFailureCount + 1,
        }),
      }
    }
    case SHOW_ALTS_INCREASED_PREMIUM_MODAL: {
      const { isIncreasedPremiumModal } = payload
      return {
        ...state,
        isIncreasedPremiumModal,
      }
    }
    case VALIDATE_QUOTE_INIT: {
      return {
        ...state,
        isFetchingValidation: true,
      }
    }
    case VALIDATE_QUOTE_SUCCESS: {
      return {
        ...state,
        isFetchingValidation: false,
        quoteValidation: payload,
        quoteValidationError: null,
      }
    }
    case VALIDATE_QUOTE_ERROR:
    case VALIDATE_QUOTE_FAILURE: {
      return {
        ...state,
        isFetchingValidation: false,
        quoteValidationError: payload,
      }
    }
    case SET_IS_ALTS_CALCULATE_ERROR_MODAL: {
      return {
        ...state,
        isAltsCalculateErrorModal: payload,
      }
    }
    case RESET_ALTS_CALCULATE_FAILURE_COUNT: {
      return {
        ...state,
        calculateFailureCount: 0,
      }
    }
    case SET_IS_VALIDATION_ERROR: {
      return {
        ...state,
        isValidationError: payload,
      }
    }
    case RESET_ALTERATIONS: {
      return initialState
    }

    case SET_OR_TOGGLE_POLICYSELECTION_KEY: {
      const { policyIdKeys } = payload
      const { policySelectionMap } = state

      return {
        ...state,
        policySelectionMap: {
          ...policySelectionMap,
          ...policyIdKeys.reduce((allKeys, eachKey) => {
            policySelectionMap[eachKey] === undefined
              ? (allKeys[eachKey] = true)
              : (allKeys[eachKey] = !policySelectionMap[eachKey])
            return allKeys
          }, {}),
        },
      }
    }

    case SELECT_LIST_OF_POLICIES: {
      const { policiesIdList } = payload
      return {
        ...state,
        policySelectionMap: policiesIdList.reduce((accumulator, bancsPolicyNo) => {
          accumulator[bancsPolicyNo] = true
          return accumulator
        }, {}),
      }
    }

    case DESELECT_ALL_POLICIES: {
      const { policySelectionMap } = state
      return {
        ...state,
        policySelectionMap: Object.keys(policySelectionMap).reduce((accumulator, bancsPolicyNo) => {
          accumulator[bancsPolicyNo] = false
          return accumulator
        }, {}),
      }
    }

    case DELETE_ALTERATIONS_IN_QUOTE: {
      return {
        ...state,
        isValidationError: false,
        altsCalculateQuoteError: null,
        isAltsQuoteDownloaded: false,
        quoteURN: '',
      }
    }

    case RESET_ALTERATIONS_QUOTE_ERROR: {
      return {
        ...state,
        altsCalculateQuoteError: null,
      }
    }

    case SET_SUM_INSURED_ERRORS: {
      return {
        ...state,
        sumInsuredErrors: payload,
      }
    }

    case RESET_SUM_INSURED_ERRORS: {
      return {
        ...state,
        sumInsuredErrors: {},
      }
    }

    case SET_IS_CONFIRM_LEAVE_MODAL: {
      const { isConfirmLeaveModal, confirmLeaveModalRedirectURL, confirmLeaveModalItemID } = payload
      return {
        ...state,
        isConfirmLeaveModal,
        confirmLeaveModalRedirectURL,
        confirmLeaveModalItemID,
      }
    }

    case SET_IS_ALTS_QUOTE_DOWNLOADED: {
      return {
        ...state,
        isAltsQuoteDownloaded: true,
      }
    }

    case SET_IS_ALTS_QUOTE_URN: {
      return {
        ...state,
        quoteURN: payload,
      }
    }

    case SET_IS_ALTS_QUOTE_SUBMITTED: {
      return {
        ...state,
        isAltsQuoteSubmitted: true,
      }
    }

    case ALTER_SUM_INSURED:
    case RESET_SUM_INSURED:
    case REMOVE_BENEFIT_OPTION:
    case CANCEL_BENEFIT_OPTION:
    case CHANGE_WAITING_BENEFIT_PERIOD:
    case RESET_WAITING_BENEFIT_PERIOD:
    case ALTS_REMOVE_BENEFIT:
    case ALTER_PAYMENT_FREQUENCY:
    case RESET_PAYMENT_FREQUENCY: {
      return {
        ...state,
        isAltsQuoteDownloaded: false,
        isAltsQuoteSubmitted: false,
        quoteURN: '',
      }
    }

    case SET_IS_SUBMISSION_IN_PROGRESS: {
      return {
        ...state,
        isSubmissionInProgress: payload,
      }
    }

    case SET_IS_MAX_DOWNLOAD_LIMIT_MODAL: {
      return {
        ...state,
        isMaxDownloadLimitModal: payload,
      }
    }

    case SET_ALTS_QUOTE_SUBMITTED_POLICIES: {
      const { policySelectionMap, policiesSubmitted = [] } = state
      const newPoliciesSubmitted = Object.keys(policySelectionMap).filter(
        policyId => policySelectionMap[policyId] && !policiesSubmitted.includes(policyId)
      )
      return {
        ...state,
        policiesSubmitted: [...policiesSubmitted, ...newPoliciesSubmitted],
      }
    }

    default: {
      return state
    }
  }
}

export default alteration
