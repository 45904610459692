import React from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'

// components
import { Card, Chip, RadioGroup, Radio, Heading } from '@mlcl-digital/mlcl-design'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'

// types
import {
  PolicyData,
  InflationProofingQuoteFields,
} from '../../../../../../types/components/InflationProofing'

// styles
import styles from './policyCard.styles'

// Redux
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../../../actions'

// utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../../../utils/sitecoreUtils'

// constants
import { POLICY_FREQUENCY_ANNUAL } from '../../../../../../constants/policies'
import { REPORTING_PAYMENT_FREQUENCY_OPTIONS } from '../../../../../../constants/forms'

type props = {
  policyData: PolicyData
  fields: InflationProofingQuoteFields
}

const Header = styled('header')(styles.header)
const PolicyDetails = styled('div')(styles.policyDetails)
const OptOutSection = styled('div')(styles.optOutSection)
const CoverSection = styled('div')(styles.coverSection)
const BenefitName = styled(Heading)(styles.benefitName)
const PremiumSaved = styled('div')(styles.premiumSaved)
const ErrorMessage = styled('div')(styles.errorMessage)
const PremiumIncreasedMessage = styled('div')(styles.premiumIncreasedMessage)

const PolicyCard = ({ policyData, fields }: props) => {
  const dispatch = useDispatch()
  const {
    policyNumber,
    productType,
    anniversary,
    lifeInsured,
    collectionFrequency,
    benefitDataWithEscalation = [],
    policyPremiumWithEscalation,
    isSelected,
    linkedPolicies,
    benefitDataWithoutEscalation = [],
    policyPremiumWithoutEscalation,
    savings,
    linkedInternalPolicyList = [],
    annualSavings,
    hasDifferentPrimaryLifeInsured,
    hasDifferentAnniversaryDate,
    hasPremiumIncreased,
  } = policyData

  const getPolicyDisabledErrorMessage = () => {
    let errorMessage = ''
    if (hasDifferentPrimaryLifeInsured && hasDifferentAnniversaryDate) {
      errorMessage = fields.DifferentAnniversaryDateAndLifeInsured?.value
    } else if (hasDifferentPrimaryLifeInsured) {
      errorMessage = fields.DifferentPrimaryLifeInsured.value
    } else if (hasDifferentAnniversaryDate) {
      errorMessage = fields.DifferentAnniversaryDate.value
    }
    return errorMessage
  }

  const toggleInflationProofing = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.setOrTogglePolicySelectionKeys(linkedInternalPolicyList))
  }

  const policyDisabledErrorMessage = getPolicyDisabledErrorMessage()

  return (
    <Card
      header={
        <Header>
          <Heading variant="h3">
            {fields.Policy?.value.replace('##', policyNumber)}
            <Chip variant={productType === 'Inside Super' ? 'default' : 'important'}>
              {productType}
            </Chip>
          </Heading>
          <PolicyDetails>
            <span>{`${fields.AnniversaryDate.value}:`}</span>
            <span>{anniversary}</span>
          </PolicyDetails>
          <PolicyDetails>
            <span>{`${fields.LivesInsured.value}:`}</span>
            <span>{lifeInsured}</span>
          </PolicyDetails>
        </Header>
      }
      footer={
        <>
          <div>
            <dl>
              <dt>{renderTextField(fields.ExistingPremium)}</dt>
              <dd>{`${policyPremiumWithEscalation} ${collectionFrequency}`}</dd>
            </dl>
          </div>
          {isSelected && (
            <div>
              <dl>
                <dt>{renderTextField(fields.NewPremium)}</dt>
                <dd>{`${policyPremiumWithoutEscalation ?? ''} ${collectionFrequency}`}</dd>
              </dl>
            </div>
          )}
          {policyDisabledErrorMessage && (
            <ErrorMessage>
              <Icon iconName={['far', 'circle-exclamation']} />
              <span>{policyDisabledErrorMessage}</span>
            </ErrorMessage>
          )}
          {savings && (
            <PremiumSaved>
              <div>
                {renderTextField(
                  hasPremiumIncreased ? fields.PremiumIncreased : fields.SavePremium
                )}
              </div>
              <div>
                <span>{renderTextField(fields.Estimated)}</span>
                <span>
                  {` ${savings} ${collectionFrequency}`}
                  {annualSavings
                    ? ` / ${annualSavings} ${
                        REPORTING_PAYMENT_FREQUENCY_OPTIONS.find(
                          frequency => frequency.value === POLICY_FREQUENCY_ANNUAL
                        )?.label?.toLowerCase() || ''
                      }`
                    : null}
                </span>
              </div>
            </PremiumSaved>
          )}
          {isSelected && hasPremiumIncreased && (
            <PremiumIncreasedMessage>
              <Icon iconName={['far', 'triangle-exclamation']} />
              <span>{renderTextField(fields.PremiumIncreasedDescription, true)}</span>
            </PremiumIncreasedMessage>
          )}
        </>
      }
      styleOverrides={{
        body: styles.body,
        footer: styles.footer,
      }}
    >
      <>
        <OptOutSection>
          <div>
            {linkedPolicies && linkedPolicies.length > 0 && (
              <>
                <Icon iconName={['far', 'link']} />
                <span>
                  {linkedPolicies.map(policyInfo => (
                    <>{fields.ConnectedToPolicy?.value.replace('##', policyInfo.split(' ')[0])}</>
                  ))}
                </span>
              </>
            )}
          </div>
          <div>
            <span>{renderTextField(fields.InflationProofing)}</span>
            <RadioGroup type="inline" styleOverrides={styles.radioGroup}>
              <Radio
                name={`inflationProofing${policyNumber}Keep`}
                htmlFor={`inflationProofingKeep${policyNumber}Keep`}
                text={fields.Keep?.value}
                disabled={hasDifferentPrimaryLifeInsured || hasDifferentAnniversaryDate}
                value="Keep"
                checked={!isSelected}
                handleOnChange={toggleInflationProofing}
              />
              <Radio
                name={`inflationProofing${policyNumber}OptOut`}
                htmlFor={`inflationProofingOptOut${policyNumber}OptOut`}
                text={fields.OptOut?.value}
                disabled={hasDifferentPrimaryLifeInsured || hasDifferentAnniversaryDate}
                value="OptOut"
                checked={isSelected}
                handleOnChange={toggleInflationProofing}
              />
            </RadioGroup>
          </div>
        </OptOutSection>
        <CoverSection>
          <div>
            <Heading variant="h3">{renderTextField(fields.Existing)}</Heading>
            {benefitDataWithEscalation.length > 0 &&
              benefitDataWithEscalation.map(({ benefitName, benefitAmount }) => (
                <>
                  <BenefitName variant="h4" size="SMD">
                    <Icon iconName={['far', 'umbrella-simple']} />
                    {benefitName}
                  </BenefitName>
                  <dl>
                    <dt>{`${fields.CoverAmount?.value}:`}</dt>
                    <dd>
                      <span>{benefitAmount}</span>
                    </dd>
                  </dl>
                </>
              ))}
          </div>
          {isSelected && (
            <div>
              <Heading variant="h3">{renderTextField(fields.New)}</Heading>
              {benefitDataWithoutEscalation.length > 0 &&
                benefitDataWithoutEscalation.map(({ benefitName, benefitAmount }) => (
                  <>
                    <BenefitName variant="h4" size="SMD">
                      <Icon iconName={['far', 'umbrella-simple']} />
                      {benefitName}
                    </BenefitName>
                    <dl>
                      <dt>{`${fields.CoverAmount?.value}:`}</dt>
                      <dd>
                        <span>{benefitAmount}</span>
                        <Chip variant="selected">{renderTextField(fields.New)}</Chip>
                      </dd>
                    </dl>
                  </>
                ))}
            </div>
          )}
        </CoverSection>
      </>
    </Card>
  )
}

export default PolicyCard
