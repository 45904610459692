export const LOGIN_ROUTE = '/'
export const DASHBOARD_ROUTE = '/dashboard'
export const LOGOUT = '/logout'
export const PERSONAL_STATEMENT = '/personalstatement'
export const ADVISER_REGISTRATION = '/adviser-registration'
export const TELEUNDERWRITER = '/teleunderwriter'
export const URE_TOOL = '/ure-tool'
export const PAYMENTS = '/payments'
export const APPLICATION_OVERVIEW_ROUTE = '/application'
export const APPLICATION_DECLARATION_ROUTE = '/application/declaration'
export const APPLICATION_REVIEW_ROUTE = '/application/review'
export const APPLICATION_SEARCH_ROUTE = '/application/search'
export const APPLICATION_DETAILS_ROUTE = '/application/details'
export const APPLICATION_CUSTOMER_COMPLETES_ROUTE = '/application/customer-completes'
export const APPLICATION_PERSONAL_STATEMENT_ROUTE = '/application/personal-statement'
export const APPLICATION_POLICY_SETUP = '/application/policy-setup'
export const APPLICATION_UW_ENGINE_ROUTE = '/application/underwriting/engine'
export const APPLICATION_UW_RESULTS_ROUTE = '/application/underwriting/results'
export const APPLICATION_UW_ROUTE = '/application/underwriting'
export const ALTERATIONS_APPLICATION_SUMMARY = '/RejectCPI/AltsAppSummary'
export const INFLATION_PROOFING_APPLICATION_SUMMARY = '/InflationProofing/AltsAppSummary'
export const DECREASE_ALTS_APPLICATION_SUMMARY = '/DecreaseCover/DecreaseAppSummary'
export const DECREASE_ALTS_QUOTE_ROUTE = '/DecreaseCover/Alterations'

export const PAYMENT_DETAILS = '/payment-details'

export const INACTIVE_ADVISER = '/inactive-adviser'
export const QUOTE_TOOL = '/quote-tool'
export const QUOTE_SUMMARY_ROUTE = '/quote-summary'
export const SUPPORT_STAFF_DECLARATION_ROUTE = '/support-staff/declaration'
export const SUPPORT_STAFF_SELECT_ADVISER_ROUTE = '/support-staff/select-adviser'
export const CUSTOMER_PERSONAL_DETAILS = '/customerPersonalDetails'
export const CUSTOMER_MAKE_A_PAYMENT = '/payment'
export const UPDATE_MOBILE = '/UpdateMobile'
export const REJECT_CPI_LANDING_PAGE = '/RejectCPI'
export const INFLATION_PROOFING_LANDING_PAGE = '/InflationProofing'
export const DECREASE_COVER_LANDING_PAGE = '/DecreaseCover'
export const CUSTOMER_ADVISER = '/your-adviser'
export const PREMIUM_CALCULATOR_LANDING_PAGE = '/covermanager'
export const PREMIUM_CALCULATOR_ROUTE = '/covermanager/alterations'
export const FORGOT_USERNAME = '/forgot-username'

export const CHANGE_ADVISER_NAV_EXCLUSION_ROUTES = [
  QUOTE_TOOL,
  APPLICATION_OVERVIEW_ROUTE,
  APPLICATION_DECLARATION_ROUTE,
  APPLICATION_REVIEW_ROUTE,
  APPLICATION_DETAILS_ROUTE,
  APPLICATION_CUSTOMER_COMPLETES_ROUTE,
  APPLICATION_PERSONAL_STATEMENT_ROUTE,
  APPLICATION_POLICY_SETUP,
  APPLICATION_UW_ENGINE_ROUTE,
  APPLICATION_UW_RESULTS_ROUTE,
  APPLICATION_UW_ROUTE,
  QUOTE_SUMMARY_ROUTE,
  URE_TOOL,
]
