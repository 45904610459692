// v2 is initially created for Inflation proofing uplift in CP
// v2 might also be used for the uplift in AP in future, code changes will be required
import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Heading, Button, Tooltip, Chip, Notification } from '@mlcl-digital/mlcl-design'
import Table from '@mlcl-digital/mlcl-design/lib/base/Table'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import ProgressBar from '@mlcl-digital/mlcl-design/lib/base/ProgressBar'

// types
import { Fields, Props } from '../../../types/components/AltsLandingPage'

// selectors
import {
  getAlterationType,
  getAlterationTypeSelectedByUser,
  // @ts-expect-error File not in typescript
} from '../../../selectors/alterations'

// utils
import { createEvent } from '../../../utils/telemetry'
// @ts-expect-error File not in typescript
import { renderTextField } from '../../../utils/sitecoreUtils'
// @ts-expect-error File not in typescript
import history from '../../../utils/browserHistory'

// styles
import styles from './styles'

// actions
import {
  setAlterationTypeForAlterationsRoute,
  deselectAllPolicies,
  setAlterationTypeSelectedByUser,
  // @ts-expect-error File not in typescript
} from '../../../actions/creators/alterations'
// @ts-expect-error File not in typescript
import { createAltsQuote } from '../../../actions/creators/createQuote'
import { getAlterationPoliciesData } from '../../../selectors/alterationPolicies'

// constants
import { DASHBOARD_ROUTE } from '../../../constants/routes'

const PageContainer = styled('div')(styles.pageContainer)
const Description = styled('p')(styles.description)
const InflationProofingDetails = styled('div')(styles.inflationProofingDetails)
const TooltipContainer = styled('div')(styles.tooltipContainer)
const StyledNotification = styled(Notification)(styles.notification)

const columns = (
  {
    PolicyNumber,
    Anniversary,
    LifeInsured,
    DaysRemaining,
    ReasonOptOut,
    DaysRemainingTooltip,
  }: Fields,
  isEligible: boolean
) => [
  {
    Header: PolicyNumber?.value,
    accessor: 'policyNumber',
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'productType',
    disableSortBy: true,
    Cell: ({ value }: { value: string }) => (
      <Chip variant={value === 'Inside Super' ? 'default' : 'important'}>{value}</Chip>
    ),
  },
  {
    Header: Anniversary?.value,
    accessor: 'anniversary',
    disableSortBy: true,
  },
  {
    Header: LifeInsured?.value,
    accessor: 'lifeInsured',
    disableSortBy: true,
  },
  ...(isEligible
    ? [
        {
          Header: (
            <div>
              <span>{DaysRemaining?.value}</span>
              <TooltipContainer>
                <span data-tip data-for="days-remaining">
                  <Icon iconName={['far', 'circle-info']} />
                </span>
                <Tooltip id="days-remaining">{renderTextField(DaysRemainingTooltip, true)}</Tooltip>
              </TooltipContainer>
            </div>
          ),
          accessor: 'daysRemaining',
          disableSortBy: true,
          Cell: ({
            value: daysRemaining,
          }: {
            value: { variant: 'danger' | 'default' | 'warning'; percentage: number; label: number }
          }) => <ProgressBar {...daysRemaining} />,
        },
      ]
    : []),
  ...(!isEligible
    ? [
        {
          Header: ReasonOptOut?.value,
          accessor: 'reasonOptOut',
          disableSortBy: true,
        },
      ]
    : []),
]

const AltsLandingPage = ({ fields, params }: Props) => {
  const dispatch = useDispatch()
  const alterationTypeForAnalytics: string = useSelector(getAlterationType())
  const alterationType = params.AlterationType
  const eligiblePolicies = useSelector(getAlterationPoliciesData(alterationType, true, fields))
  const inEligiblePolicies = useSelector(getAlterationPoliciesData(alterationType, false, fields))
  const alterationTypeSelectedByUser = useSelector(getAlterationTypeSelectedByUser)

  const COLUMNS_ELIGIBLE_POLICIES = columns(fields, true)
  const COLUMNS_INELIGIBLE_POLICIES = columns(fields, false)

  useEffect(() => {
    dispatch(
      createAltsQuote({
        alterationType,
      })
    )

    dispatch(deselectAllPolicies())

    const tagEvent = createEvent({
      GA: {
        category: alterationTypeForAnalytics,
        action: `${alterationTypeForAnalytics} landing page`,
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationTypeForAnalytics} - landing page`,
        },
      },
    })
    tagEvent.end()
    // clear the flag so that user cannot enter flow in middle
    return () => {
      dispatch(setAlterationTypeSelectedByUser(''))
    }
  }, [])

  useEffect(() => {
    // if user did not come by sequencial flow, redirect to dashboard
    if (params.AlterationType !== alterationTypeSelectedByUser) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      history.push(DASHBOARD_ROUTE)
    }
  }, [])

  const handleClick = useCallback(() => {
    // setting the flag for next page in the flow
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(setAlterationTypeForAlterationsRoute(alterationType))

    const tagEvent = createEvent({
      GA: {
        category: alterationTypeForAnalytics,
        action: `Enter ${alterationTypeForAnalytics} alteration`,
      },
      Splunk: {
        attributes: {
          'workflow.name': `Enter ${alterationTypeForAnalytics} alteration`,
        },
      },
    })
    tagEvent.end()

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    history.replace(fields.StartApplicationButton?.value?.href)
  }, [alterationTypeForAnalytics])

  return (
    <PageContainer>
      <InflationProofingDetails>
        <Heading variant="h2" size="LG">
          {renderTextField(fields.Heading)}
        </Heading>
        <Description>{renderTextField(fields.Description, true)}</Description>
        <Button variant="primary" disabled={eligiblePolicies.length === 0} onClick={handleClick}>
          {fields.StartApplicationButton?.value?.text}
        </Button>
        {eligiblePolicies.length === 0 && (
          <StyledNotification variant="info">
            <Icon iconName={['far', 'circle-info']} />
            <span>{renderTextField(fields.OptOutUnavailable)}</span>
          </StyledNotification>
        )}
      </InflationProofingDetails>
      {eligiblePolicies.length > 0 && (
        <>
          <Heading variant="h3" size="LG">
            {renderTextField(fields.PoliciesEligibleHeading)}
          </Heading>
          <Table
            pageSize={20}
            data={eligiblePolicies}
            columns={COLUMNS_ELIGIBLE_POLICIES}
            styleOverrides={styles.styleOverrides}
          />
        </>
      )}
      {inEligiblePolicies.length > 0 && (
        <>
          <Heading variant="h3" size="LG">
            {renderTextField(fields.PoliciesIneligibleHeading)}
          </Heading>
          <Table
            pageSize={20}
            data={inEligiblePolicies}
            styleOverrides={styles.styleOverrides}
            columns={COLUMNS_INELIGIBLE_POLICIES}
          />
        </>
      )}
    </PageContainer>
  )
}

export default AltsLandingPage
