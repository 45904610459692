/* eslint-disable @typescript-eslint/unbound-method */
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { Button } from '@mlcl-digital/mlcl-design'
import BurgerMenu from '@mlcl-digital/mlcl-design/lib/base/BurgerMenu'
import get from 'lodash/get'
import { Placeholder, ComponentRendering, RouteData } from '@sitecore-jss/sitecore-jss-react'
import debounce from 'lodash/debounce'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { createEvent } from '../../../../../utils/telemetry'
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../../actions'
import { OKTA_CHANGE_PASSWORD_MODAL } from '../../../../../actions/types/okta'

// components.
// @ts-expect-error file not in typescript
// eslint-disable-next-line import/named
import { IconUser16 } from '../../../../atoms/Icons'
import Logo from '../../../../atoms/MLCAcendaLogo'
import NavDropdownUplift from '../../../../molecules/NewNavDropdown'
// @ts-expect-error file not in typescript
import NavItem from '../../../../atoms/NavItem'
// @ts-expect-error file not in typescript
import ChangePassword from '../../../ChangePassword'

// styles.
import styles from './newMainNavigation.styles'
// @ts-expect-error file not in typescript
import { themeConsumer } from '../../../../../styles/ThemeContext'
import {
  reduceAuthorableFields,
  renderTextField,
  reduceContentListFields,
  // @ts-expect-error file not in typescript
} from '../../../../../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import { logout } from '../../../../../utils/logoutUtils'
// @ts-expect-error file not in typescript
import { getProfileName } from '../../../../../utils/profileUtils'
// @ts-expect-error file not in typescript
// eslint-disable-next-line import/named
import { getAdviserNo } from '../../../../../utils/cookieUtils'

// constants
import { NAV_LOGOUT_ITEM_ID, NAV_CHANGE_PASSWORD_ITEM_ID } from '../../../../../constants/sitecore'
import { ADVISOR_PORTAL } from '../../../../../constants/site'
import { DASHBOARD_ROUTE } from '../../../../../constants/routes'
import {
  CP_PRIMARY_MAKE_A_PAYMENT_NAV_ID,
  DOCUMENTS_NAV_ID,
  MY_ADVISER_NAV_ID,
  TASK_DASHBOARD_NAV_ID,
} from '../../../../../constants/navigation'

// types
import { Store } from '../../../../../types/store'
import { NewMainNavigationFields, Ary } from '../../../../../types/components/NewMainNavigation'

type MainNavigationProps = {
  // redux actions
  // eslint-disable-next-line @typescript-eslint/ban-types
  actions: Record<string, Function>
  // sitecore fields
  fields: NewMainNavigationFields
  // sitecore rendering
  rendering: ComponentRendering | RouteData
  // redux modal state
  modal: Store['modal']
  hideNavigationItems: boolean
  excludeSecondaryNavItems: string[]
  advisor: Store['advisor']
  customerPersonalDetails: Store['customerPersonalDetails']
  navigation: Store['navigation']
  history: RouteComponentProps['history']
  activeSupportStaff: Store['supportStaff']['activeSupportStaff']
  sidebar: Store['sidebar']
}

type MainNavigationState = {
  // Sets the dropdown compoment toggle to open
  dropdown: object
  // Sets scrolling state for the header
  header: {
    isScrolling: boolean
  }
  // track current active link
  activeLink: string
  showChangePassword: boolean
  hasDOMLoaded: boolean
}

const Header = styled('header')(styles.header)
const LogoContainer = styled('div')(styles.logoContainer)

const Nav = styled('nav')(styles.nav)
const Dropdown = styled(NavDropdownUplift)()

const List = styled('ul')(styles.list)
const Item = styled('li')(styles.item)
const ItemLink = styled(Item.withComponent(NavItem))(styles.itemLink)
const CustomerNavLinks = styled('div')(styles.helpLink)

const NavSubSection = styled('div')(styles.navSubSection)
const NavTabletSection = styled('div')(styles.navTabletSection)
const ProfileDropdown = styled(Dropdown)(styles.profileDropdpwn)
const PrimaryDropdown = styled(Dropdown)(styles.primaryDropdown)
const Overlay = styled('div')(styles.overlay)

const QuickAction = styled(Item.withComponent('li'))(styles.quickAction)

const ProfileIcon = styled(IconUser16)(styles.profileIcon)
const ProfileNameText = styled('span')(styles.profileNameText)

const QuickActionButton = styled(Button)(styles.quickActionButton)
class MainNavigation extends Component<MainNavigationProps, MainNavigationState> {
  constructor(props: MainNavigationProps) {
    super(props)

    this.state = {
      dropdown: {},
      header: {
        isScrolling: false,
      },
      showChangePassword: false,
      hasDOMLoaded: false,
      activeLink: '/dashboard',
    }

    this.handleScroll = debounce(this.handleScroll.bind(this), 5)
    this.handleOnClickMenu = this.handleOnClickMenu.bind(this)
    this.dropdownClose = this.dropdownClose.bind(this)
  }

  componentDidMount() {
    const {
      actions: { initModal },
    } = this.props
    initModal(OKTA_CHANGE_PASSWORD_MODAL)
    window.addEventListener('scroll', this.handleScroll)
    this.setState(() => ({
      hasDOMLoaded: true,
    }))
  }

  componentDidUpdate() {
    const { history } = this.props
    const { activeLink } = this.state

    if (history?.location?.pathname !== activeLink) {
      this.setState({
        activeLink: history?.location?.pathname,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  setScrollingState(isScrolling: boolean) {
    this.setState(prevState => ({
      header: {
        ...prevState.header,
        isScrolling,
      },
    }))
  }

  handlePrimaryItemClick = (nav: Ary) => {
    this.setState({
      activeLink: nav?.fields?.href?.value,
    })
    const eventMap = {
      [TASK_DASHBOARD_NAV_ID]: {
        category: 'Tasks from dashboard',
        action: 'AP - tasks from dashboard',
      },
      [MY_ADVISER_NAV_ID]: {
        category: 'Customer selects Your adviser',
        action: 'CP - select adviser',
      },
      [DOCUMENTS_NAV_ID]: { category: 'Customer selects Documents', action: 'Select' },
      [CP_PRIMARY_MAKE_A_PAYMENT_NAV_ID]: {
        category: 'Customer selects Make a payment',
        action: 'CP - make a payment',
      },
    }
    const item = nav?.fields?.id?.value as keyof typeof eventMap
    if (item) {
      const tagEvent = createEvent({
        GA: eventMap[item],
        Splunk: {
          attributes: {
            'workflow.name': `${eventMap[item].category} - ${eventMap[item].action}`,
          },
        },
      })
      tagEvent.end()
    }
  }

  filterSecondaryNavItems = (navItemsList: Ary[]): object[] => {
    const { excludeSecondaryNavItems } = this.props
    if (!excludeSecondaryNavItems) {
      return navItemsList
    }
    return navItemsList.filter(item => excludeSecondaryNavItems.indexOf(item.id) === -1)
  }

  menuItemClickHandler = (
    e: React.MouseEvent<HTMLElement>,
    item: {
      id: string
      href: string
      text: string
    }
  ) => {
    const {
      actions: { showModal, signOut },
      history,
    } = this.props

    this.setState({
      activeLink: item?.href?.split('#')?.[0],
    })

    if (item.id === NAV_CHANGE_PASSWORD_ITEM_ID) {
      e.preventDefault()

      showModal(OKTA_CHANGE_PASSWORD_MODAL)
    }

    if (item.id === NAV_LOGOUT_ITEM_ID) {
      e.preventDefault()
      logout({
        oktaSignOutAction: signOut,
        currentRoute: get(history, 'location.pathname', '/') as string,
      })
    }
  }

  dropdownClose() {
    this.setState(prevState => ({
      ...prevState,
      dropdown: {},
    }))
  }

  handleOnClickMenu() {
    const {
      actions: { toggleBurgerMenu },
      navigation: { isOpen: menuIsOpen },
    } = this.props

    toggleBurgerMenu(!menuIsOpen)
  }

  handleScroll() {
    const {
      header: { isScrolling },
    } = this.state

    if (window.scrollY === 0 && isScrolling === true) {
      this.setScrollingState(false)
    } else if (window.scrollY !== 0 && isScrolling !== true) {
      this.setScrollingState(true)
    }
  }

  render() {
    const {
      header: { isScrolling },
      dropdown,
      hasDOMLoaded,
      activeLink,
    } = this.state

    const {
      actions,
      hideNavigationItems,
      fields,
      rendering,
      modal,
      advisor,
      customerPersonalDetails,
      navigation: { isOpen: menuIsOpen, showNotificationBanner },
      sidebar,
      activeSupportStaff,
    } = this.props
    const dropdownOpen = Object.keys(dropdown).length !== 0
    const { openSidebar } = actions

    const { logoHorizontal, logoVertical, profile, primary, help, quicklinkAction } = fields

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const isChangePasswordModalVisible =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      modal && modal[OKTA_CHANGE_PASSWORD_MODAL] && modal[OKTA_CHANGE_PASSWORD_MODAL].isModalVisible

    let profileDisplayName
    if (getAdviserNo()) {
      // support staff
      profileDisplayName =
        activeSupportStaff.firstName || (profile?.fields?.text?.value ?? 'Account')
    } else {
      profileDisplayName = getProfileName(
        advisor,
        customerPersonalDetails,
        profile?.fields?.text?.value ?? 'Account'
      )
    }

    const ProfileName = (
      <ProfileNameText>
        <ProfileIcon /> {profileDisplayName}
      </ProfileNameText>
    )

    const isAdviserPortal = process.env.SITE === ADVISOR_PORTAL

    const CreateQuoteButton = () => {
      if (isAdviserPortal && quicklinkAction && quicklinkAction.fields) {
        return (
          <QuickAction>
            <QuickActionButton
              variant="primary"
              onClick={() => {
                openSidebar('createQuote')
                const tagEvent = createEvent({
                  GA: {
                    category: 'Create quote',
                    action: 'Select',
                  },
                  Splunk: {
                    attributes: {
                      'workflow.name': 'Create quote - Select',
                    },
                  },
                })
                tagEvent.end()
              }}
            >
              {renderTextField(quicklinkAction?.fields?.text ?? '')}
            </QuickActionButton>
          </QuickAction>
        )
      }
      return null
    }

    return (
      <Fragment>
        {dropdownOpen && <Overlay onClick={this.dropdownClose} />}
        {rendering && rendering.placeholders && (
          <Placeholder name="notification-banner" rendering={rendering} />
        )}
        <Header
          data-testid="main-navigation"
          isScrolling={isScrolling}
          isSidebarOpen={sidebar && sidebar.open}
          isNotificationBannerVisible={showNotificationBanner}
        >
          <LogoContainer>
            <Logo
              href={hideNavigationItems || !hasDOMLoaded ? '' : DASHBOARD_ROUTE}
              horizontalSrc={get(logoHorizontal, 'value', '')}
              verticalSrc={get(logoVertical, 'value', '')}
              onClick={() => this.setState({ activeLink: '/dashboard' })}
              alt="MLC"
            />
            {!hideNavigationItems && hasDOMLoaded && (
              <>
                <NavTabletSection>
                  {/* show Create Quote on tablet */}
                  <CreateQuoteButton />
                </NavTabletSection>
                <BurgerMenu isOpen={menuIsOpen} onClick={this.handleOnClickMenu} />
              </>
            )}
          </LogoContainer>
          {!hideNavigationItems && hasDOMLoaded && (
            <Fragment>
              <Nav isOpen={menuIsOpen}>
                <List>
                  {profile && profile.fields && !!Object.keys(profile.fields).length && (
                    <Item showOnSmall>
                      <Dropdown
                        buttonText={profileDisplayName}
                        items={reduceContentListFields(profile?.fields?.secondary ?? [])}
                        menuItemClickHandler={this.menuItemClickHandler}
                      />
                    </Item>
                  )}
                  {primary &&
                    primary.map(nav =>
                      nav.fields && nav.fields.secondary && nav.fields.secondary.length ? (
                        <Item key={nav?.fields?.text?.value ?? ''}>
                          <PrimaryDropdown
                            buttonText={renderTextField(nav?.fields?.text ?? '')}
                            items={this.filterSecondaryNavItems(
                              reduceContentListFields(nav?.fields?.secondary ?? [])
                            )}
                            variant="primary"
                            menuItemClickHandler={this.menuItemClickHandler}
                            selected={nav?.fields?.secondary
                              ?.map(i => i?.fields?.href?.value?.split('#')?.[0])
                              .includes(activeLink)}
                          />
                        </Item>
                      ) : (
                        <>
                          <ItemLink
                            key={nav?.fields?.text?.value ?? ''}
                            href={nav?.fields?.href?.value ?? ''}
                            onClick={() => this.handlePrimaryItemClick(nav)}
                            selected={activeLink === nav?.fields?.href?.value}
                          >
                            {renderTextField(nav?.fields?.text ?? '')}
                          </ItemLink>
                        </>
                      )
                    )}
                </List>
              </Nav>
              <NavSubSection>
                <List>
                  <CreateQuoteButton />
                  {help && help.fields && !!Object.keys(help?.fields).length && (
                    <ItemLink href={help?.fields?.href?.value ?? ''}>
                      <CustomerNavLinks>
                        {renderTextField(help?.fields?.text ?? '')}
                      </CustomerNavLinks>
                    </ItemLink>
                  )}
                </List>

                {profile && profile.fields && !!Object.keys(profile.fields).length && (
                  <ProfileDropdown
                    buttonText={ProfileName}
                    items={reduceContentListFields(profile?.fields?.secondary ?? [])}
                    variant="secondary"
                    menuItemClickHandler={this.menuItemClickHandler}
                  />
                )}
              </NavSubSection>
            </Fragment>
          )}
        </Header>
        {isChangePasswordModalVisible && (
          <ChangePassword
            isOpen={isChangePasswordModalVisible || false}
            fields={reduceAuthorableFields(fields)}
          />
        )}
      </Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  actions: bindActionCreators(actionCreators, dispatch),
})

const mapStateToProps = ({
  modal,
  advisor,
  customerPersonalDetails,
  navigation,
  sidebar,
  supportStaff,
}: Store) => ({
  modal,
  advisor,
  customerPersonalDetails,
  navigation,
  sidebar,
  activeSupportStaff: get(supportStaff, 'activeSupportStaff', {}),
})

export const Navigation = MainNavigation // Exported for testing.

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error withRouter params type error
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
)(themeConsumer(withRouter(MainNavigation), 'navigation'))
