import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'

// components
import { Heading, Checkbox, Modal, Variables } from '@mlcl-digital/mlcl-design'
import Button from '@mlcl-digital/mlcl-design/lib/base/Button'
// @ts-expect-error File not in typescript
import WithLoader from '../../../molecules/WithLoader'
// @ts-expect-error File not in typescript
import ScrollToTop from '../../../atoms/ScrollToTop'

// utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../utils/sitecoreUtils'
import { createEvent } from '../../../../utils/telemetry'
// @ts-expect-error File not in typescript
import history from '../../../../utils/browserHistory'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../actions'

// selectors
import {
  getAltsProductRules,
  isDecreaseQuote,
  getAlterationType,
  // @ts-expect-error file not in typescript
} from '../../../../selectors/alterations'
import {
  makeAltsLifeInsuredNameAndPartyNo,
  getIsAdviserPortal,
} from '../../../../selectors/common.selectors'

// types
import { Props } from '../../../../types/components/AltsDeclaration'
import { Store } from '../../../../types/store'

// styles
import styles from './altsDeclarationV2.styles'

// constants
import { DECREASE_COVER_GA_TAG } from '../../../../constants/alterations'
import { ACTION_TYPES, SAVE_DRAFT_APPLICATION } from '../../../../constants/application'
import {
  INFLATION_PROOFING_APPLICATION_SUMMARY,
  DECREASE_ALTS_APPLICATION_SUMMARY,
} from '../../../../constants/routes'
import { VariablesType } from '../../../../types/ComponentLibrary'
import SelectedPolicies from './components/SelectedPolicies'

const Wrapper = styled('div')(styles.wrapper)
const QuoteSection = styled('div')(styles.quoteSection)
const CTACheckbox = styled(Checkbox)(styles.aCTACheckbox)
const SubmitButton = styled(Button)(styles.submitButton)

const AltsDeclarationV2 = ({ fields }: Props) => {
  const alterationTypeForAnalytics: string = useSelector(getAlterationType())
  const isDecreaseCover = useSelector(isDecreaseQuote)
  const { firstName, lastName } = useSelector(makeAltsLifeInsuredNameAndPartyNo)
  const productRules = useSelector(getAltsProductRules)
  const isAdvisorPortal = useSelector(getIsAdviserPortal)
  const { isFetchingData, saveQuoteError, action } = useSelector(
    (state: Store) => state.createQuote
  )

  const [isChecked, setChecked] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [showModal, setShowModal] = useState(false)
  // Added state to prevent rendering of error modal on initial render
  const [skipInitialErrorRender, setErrorRender] = useState(true)

  const { colours } = Variables as VariablesType

  const dispatch = useDispatch()

  useEffect(() => {
    const tagEvent = createEvent({
      GA: {
        category: isDecreaseCover ? DECREASE_COVER_GA_TAG : alterationTypeForAnalytics,
        action: isDecreaseCover
          ? 'Decrease cover declaration - try again'
          : 'Declaration and submission page load',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${
            isDecreaseCover ? DECREASE_COVER_GA_TAG : alterationTypeForAnalytics
          } - ${
            isDecreaseCover ? 'Declaration page load' : 'Declaration and submission page load'
          }`,
        },
      },
    })
    tagEvent.end()
  }, [])

  useEffect(() => {
    setShowLoader(isFetchingData)
    if (isFetchingData) setErrorRender(false)
    if (!isFetchingData && !skipInitialErrorRender && Boolean(saveQuoteError) && !isDecreaseCover) {
      setShowModal(true)
    }
  }, [isFetchingData, saveQuoteError])

  const handleBackClick = () => {
    dispatch(actionCreators.timelinePrevState())
  }

  const handleSubmit = () => {
    const quoteCollectionName = `${firstName} ${lastName}`

    if (isChecked) {
      const consent = {
        targetedIndex: 0,
        [isAdvisorPortal ? 'isAdviserDeclarationChecked' : 'isDeclarationAuthorizationChecked']:
          isChecked,
        description: `<p>${fields.DeclarationText.value}</p><p>${fields.ConfirmationText.value}</p>`,
      }

      if (isAdvisorPortal) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        dispatch(actionCreators.updateAdviserDeclarationConsents(consent))
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        dispatch(actionCreators.updateDeclarationAuthorisationConsents(consent))
      }
    }

    if (isDecreaseCover && action === SAVE_DRAFT_APPLICATION) {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        actionCreators.updateQuoteStatus({
          actionName: ACTION_TYPES.SAVE_DIGITAL_ALTERATION,
        })
      )
    }

    if (isDecreaseCover) {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        actionCreators.saveQuote(
          (err: { status: { code: string } }) => {
            if (!err) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              history.replace(DECREASE_ALTS_APPLICATION_SUMMARY)
            }
          },
          'id-1',
          quoteCollectionName,
          firstName,
          lastName,
          productRules
        )
      )
    } else {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        actionCreators.saveSelectedPoliciesQuote(
          (err: { status: { code: string } }) => {
            if (!err) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              dispatch(actionCreators.setAltsQuoteSubmittedPolicies())
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              history.replace(INFLATION_PROOFING_APPLICATION_SUMMARY)
            }
          },
          'id-1',
          quoteCollectionName,
          firstName,
          lastName,
          productRules
        )
      )
    }

    const tagEvent = createEvent({
      GA: {
        category: isDecreaseCover ? DECREASE_COVER_GA_TAG : alterationTypeForAnalytics,
        action: 'Decrease cover - submit application',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${
            isDecreaseCover ? DECREASE_COVER_GA_TAG : alterationTypeForAnalytics
          } - Decrease cover - submit application`,
        },
      },
    })
    tagEvent.end()
  }

  const onTryAgainModalClose = () => {
    setShowModal(false)
    const tagEvent = createEvent({
      GA: {
        category: alterationTypeForAnalytics,
        action: 'Modal close - declaration page',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationTypeForAnalytics} - Modal close - declaration page`,
        },
      },
    })
    tagEvent.end()
  }

  const onTryAgainModalSubmit = () => {
    const tagEvent = createEvent({
      GA: {
        category: alterationTypeForAnalytics,
        action: 'Try again on modal - declaration page',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationTypeForAnalytics} - Try again on modal - declaration page`,
        },
      },
    })
    tagEvent.end()
    setShowModal(false)
    handleSubmit()
  }

  const onCheckBoxChecked = () => {
    setChecked(!isChecked)
    const category: string = isDecreaseCover ? DECREASE_COVER_GA_TAG : alterationTypeForAnalytics
    const tagEvent = createEvent({
      GA: {
        category,
        action: !isChecked ? 'Decrease cover - declaration on' : 'Decrease cover - declaration off',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${category} - ${!isChecked ? 'Declaration on' : 'Declaration off'}`,
        },
      },
    })
    tagEvent.end()
  }

  return (
    <>
      <WithLoader
        isLoading={showLoader}
        loaderProps={{ type: 'tab' }}
        childrenBackgroundColor={colours.tertiarySix}
      >
        <ScrollToTop>
          <Wrapper>
            <QuoteSection>
              <Heading variant="h4" size="MD">
                {renderTextField(fields.RemoveInflationProofing)}
              </Heading>
              <SelectedPolicies fields={fields} />
              <p>{renderTextField(fields.ConfirmationText)}</p>
              <div>
                <CTACheckbox
                  htmlFor="declarationCheckBox"
                  name="consentCheckBox"
                  id="declarationCheckBox"
                  text={fields.DeclarationText.value}
                  onChangeHandler={onCheckBoxChecked}
                  checked={isChecked}
                />
                <SubmitButton disabled={!isChecked} onClick={handleSubmit}>
                  {renderTextField(fields.SubmitCTA)}
                </SubmitButton>
              </div>
            </QuoteSection>
            <Button variant="secondaryWithTheme" onClick={handleBackClick}>
              {renderTextField(fields.BackCTA)}
            </Button>
          </Wrapper>
        </ScrollToTop>
      </WithLoader>
      <Modal
        isOpen={showModal}
        onClose={onTryAgainModalClose}
        title={fields.TryAgainModalTitle.value}
      >
        <p>{renderTextField(fields.TryAgainModalDesc)}</p>
        <Button type="secondary" onClick={onTryAgainModalSubmit}>
          {renderTextField(fields.TryAgainModalCTA)}
        </Button>
      </Modal>
    </>
  )
}

export default AltsDeclarationV2
