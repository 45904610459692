// @flow
import React from 'react'
// Organism
import AltsLandingPageV2Organism from '../../../organisms/AltsLandingPageV2'
import { isBrowser } from '../../../../utils/browserUtils'

type AltsLandingPageProps = {
  // Sitecore fields.
  fields: Object<Object>,
  // Params
  params: Object,
}

const AltsLandingPageV2 = ({ fields, params }: AltsLandingPageProps) =>
  isBrowser() && <AltsLandingPageV2Organism fields={fields} params={params} />

export default AltsLandingPageV2
