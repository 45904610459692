// @flow
import get from 'lodash/get'
// types.
import { EXPERIENCE_API } from '../types/experienceApi'
import {
  ALTERATION_POLICIES_FETCH,
  MEMBER_CONTACT_DETAILS_UPDATE,
  MEMBER_CONTACT_DETAILS_FORM_RESET,
  GET_ESCALATION,
  GET_PRODUCT_RULES,
  GET_ALTERATIONS_RULES,
  SET_ALTERATIONS_POLICIES,
  SET_IS_ESCALATION_API_DONE,
  SET_USER_ALTERATIONTYPE_SELECTION,
  SET_USER_ALTERATIONTYPE_SELECTION_ALTERATIONSROUTE,
  RESET_ALTERATIONS,
  SET_IS_POLICY_API_DONE_FOR_POLICY_WITHIN_ANIV_PERIOD,
  SET_IS_ALTS_CALCULATE_ERROR_MODAL,
  RESET_ALTS_CALCULATE_FAILURE_COUNT,
  VALIDATE_QUOTE,
  SET_IS_VALIDATION_ERROR,
  RESET_ALTS_PRODUCT_RULES_FAILED,
  RESET_ALTS_PRODUCT_RULES,
  SHOW_ALTS_INCREASED_PREMIUM_MODAL,
  SET_OR_TOGGLE_POLICYSELECTION_KEY,
  SELECT_LIST_OF_POLICIES,
  DESELECT_ALL_POLICIES,
  SET_SUM_INSURED_ERRORS,
  RESET_SUM_INSURED_ERRORS,
  RESET_ALTERATIONS_QUOTE_ERROR,
  SET_IS_CONFIRM_LEAVE_MODAL,
  SET_IS_ALTS_QUOTE_DOWNLOADED,
  SET_IS_ALTS_QUOTE_URN,
  SET_IS_ALTS_QUOTE_SUBMITTED,
  SET_IS_SUBMISSION_IN_PROGRESS,
  SET_IS_MAX_DOWNLOAD_LIMIT_MODAL,
  SET_ALTS_QUOTE_SUBMITTED_POLICIES,
} from '../types/alterations'
import { UPDATE_CONTACT_METHODS_OF_MEMBER } from '../types/createQuote'

// constants.
import {
  EXPERIENCE_API_BASE,
  EXPERIENCE_API_VERSION_1,
  EXPERIENCE_API_REFERENCE,
} from '../../constants/apis'

// utils
import { getBancsCustomerNumber } from '../../utils/cookieUtils'
import { getTotalChildSumInsured } from '../../utils/benefitLinkageUtils'
import { setModalDescription, showModal } from './modal'

export const getAlterationPolicies =
  (bancsCustomerNumber: string, effectiveDate?: string, callback: Function = () => {}) =>
  (dispatch: Function) => {
    // dispatch the action to call the api.
    let endPoint = `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/policies/${bancsCustomerNumber}?removeNuLISCheck=false&alterationCheckRequired=Y&returnBalance=Y`

    // Added effective date in the url
    if (effectiveDate) {
      endPoint = `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/policies/${bancsCustomerNumber}?removeNuLISCheck=false&alterationCheckRequired=Y&returnBalance=Y&effectiveDate=${effectiveDate}`
    }
    dispatch({
      type: EXPERIENCE_API,
      name: ALTERATION_POLICIES_FETCH,
      verb: 'GET',
      route: endPoint,
      callback: (err, dataReceived) => {
        callback(err, dataReceived)
        const policies = get(dataReceived, 'businessData.policies')
        const bancsCustomerNo = get(dataReceived, 'businessData.bancsCustomerNo')
        // pass subset of response, or during error pass whole payload down.
        return {
          bancsCustomerNo,
          policies: policies || dataReceived,
          clientId: bancsCustomerNumber,
        }
      },
    })
  }

export const setAlterationsPolicies = ({ bancsCustomerNo, policies }: Object) => ({
  type: SET_ALTERATIONS_POLICIES,
  payload: {
    bancsCustomerNo,
    policies,
    clientId: bancsCustomerNo,
  },
})

export const resetMemberContactDetails = () => ({
  type: MEMBER_CONTACT_DETAILS_FORM_RESET,
})

export const updateMemberContactDetails =
  (data: Object, bancsCustomerNo: string, callback: Function = () => {}) =>
  (dispatch: Function) => {
    // dispatch the action to call the api.
    dispatch({
      type: EXPERIENCE_API,
      name: MEMBER_CONTACT_DETAILS_UPDATE,
      verb: 'PUT',
      data: {
        member: data,
        bancsCustomerNo,
      },
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/${bancsCustomerNo}`,
      callback: (err, dataReceived) => {
        const isError = get(err, 'status.type', '') === 'error'
        callback(dataReceived, isError)
        const memberDetails = get(dataReceived, 'businessData.member', {})
        // Update all matching relationships in store: createQuote
        // based on bancsCustomerNo with the Member details (obtained from Bancs)
        dispatch({
          type: UPDATE_CONTACT_METHODS_OF_MEMBER,
          payload: {
            bancsCustomerNo,
            memberDetails,
          },
        })
        return {
          bancsCustomerNo,
          memberDetails,
        }
      },
    })
  }

export const getEscalation =
  ({ bancsPolicyNo, escalationDueDate }: Object) =>
  (dispatch: Function) => {
    dispatch({
      type: EXPERIENCE_API,
      name: GET_ESCALATION,
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/escalations/${bancsPolicyNo}`,
      query: {
        escalationDueDate,
      },
      callback: (err, dataReceived) => {
        if (err) {
          dispatch(
            setModalDescription({
              modalType: 'altsErrorModal',
              description: 'rejectCPI',
            })
          )
          dispatch(showModal('altsErrorModal'))
        }
        return dataReceived
      },
    })
  }

export const getProductRules =
  ({ productId }: Object) =>
  (dispatch: Function, getState: Function) => {
    const { alterations: { productRules } = {} } = getState()
    const isProductRuleAlreadyPresent =
      productRules && productRules.filter(product => product.productId === productId).length > 0
    if (!isProductRuleAlreadyPresent && productId) {
      dispatch({
        type: EXPERIENCE_API,
        name: GET_PRODUCT_RULES,
        verb: 'GET',
        route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/product/rules/${productId}`,
        errorPayload: {
          productId,
        },
      })
    }
  }

/**
 * action creator to get alteration rules from API
 * @param {boolean} isMinimalRules minimal rules required to show
 * policy anniversary card quicker on customer portal
 * @returns action to get alteration rules
 */
export const getAlterationRules =
  (isMinimalRules: boolean = false) =>
  (dispatch: Function, getState: Function) => {
    let policies
    let bancsCustomerNo
    if (isMinimalRules) {
      const { customerPolicies } = getState()
      policies = Object.values(customerPolicies).map(({ policyNo, startDate, status }) => ({
        bancsPolicyNo: policyNo,
        policy: {
          startDate,
          status,
        },
      }))
      bancsCustomerNo = getBancsCustomerNumber()
    } else {
      const {
        alterations: { policies: altsPolicies, bancsCustomerNo: altsBancsCustomerNo },
      } = getState()
      policies = altsPolicies
      bancsCustomerNo = altsBancsCustomerNo
    }

    dispatch({
      type: EXPERIENCE_API,
      name: GET_ALTERATIONS_RULES,
      verb: 'POST',
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_REFERENCE}/rules`,
      data: {
        businessData: {
          bancsCustomerNo,
          policies,
        },
      },
    })
  }

export const getAlterationRulesForPolicies = () => (dispatch: Function, getState: Function) => {
  const {
    policies: { data: policiesData, bancsCustomerNo },
  } = getState()

  dispatch({
    type: EXPERIENCE_API,
    name: GET_ALTERATIONS_RULES,
    verb: 'POST',
    route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_REFERENCE}/rules`,
    data: {
      businessData: {
        bancsCustomerNo,
        policies: policiesData,
      },
    },
  })
}

export const setIsEscalationAPIDone = () => ({
  type: SET_IS_ESCALATION_API_DONE,
})

export const setIsPolicyAPIDoneForPolicyWithinAnivPeriod = () => ({
  type: SET_IS_POLICY_API_DONE_FOR_POLICY_WITHIN_ANIV_PERIOD,
})

export const setAlterationTypeSelectedByUser = (alterationTypeSelectedByUser: string) => ({
  type: SET_USER_ALTERATIONTYPE_SELECTION,
  payload: {
    alterationTypeSelectedByUser,
  },
})

export const setAlterationTypeForAlterationsRoute = (alterationType: string) => ({
  type: SET_USER_ALTERATIONTYPE_SELECTION_ALTERATIONSROUTE,
  payload: {
    alterationType,
  },
})

export const resetAlterations = () => ({
  type: RESET_ALTERATIONS,
})

export const setIsAltsCalculateErrorModal = (isAltsCalculateErrorModal: boolean) => ({
  type: SET_IS_ALTS_CALCULATE_ERROR_MODAL,
  payload: isAltsCalculateErrorModal,
})

export const resetAltsCalculateFailureCount = () => ({
  type: RESET_ALTS_CALCULATE_FAILURE_COUNT,
})

// used to validate quote for decrease during reloading existing quote
export const quoteValidation = () => (dispatch, getState) => {
  const { createQuote } = getState()
  dispatch({
    verb: 'POST',
    type: EXPERIENCE_API,
    name: VALIDATE_QUOTE,
    route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_REFERENCE}/rules/policy_validation`,
    data: createQuote,
  })
}

export const setIsValidationError = (isValidationError: boolean) => ({
  type: SET_IS_VALIDATION_ERROR,
  payload: isValidationError,
})

export const resetAltsProductRules = () => ({
  type: RESET_ALTS_PRODUCT_RULES,
})

export const resetAltsProductRulesFailed = () => ({
  type: RESET_ALTS_PRODUCT_RULES_FAILED,
})

export const showAltsPremiumIncreasedModal = (isIncreasedPremiumModal: boolean = true) => ({
  type: SHOW_ALTS_INCREASED_PREMIUM_MODAL,
  payload: {
    isIncreasedPremiumModal,
  },
})

export const setOrTogglePolicySelectionKeys = (policyIdKeys: array) => ({
  type: SET_OR_TOGGLE_POLICYSELECTION_KEY,
  payload: {
    policyIdKeys,
  },
})

export const selectListOfPolicies = (policiesIdList: Array<string>) => ({
  type: SELECT_LIST_OF_POLICIES,
  payload: {
    policiesIdList,
  },
})

export const deselectAllPolicies = () => ({
  type: DESELECT_ALL_POLICIES,
})

// setting errors for updated sum insured values
export const setSumInsuredErrors = fields => (dispatch, getState) => {
  const { createQuote } = getState()
  const { activeIndex, quotes } = createQuote
  const sumInsuredErrorCollection = {}
  const { policyStructure } = quotes[activeIndex]
  policyStructure.forEach(policy => {
    policy.alteration?.alteredBenefits.forEach(alteratedBenefit => {
      const coverID = `${policy.policyNo}-${alteratedBenefit.benefitCode}-${alteratedBenefit.benefitInstanceNo}`
      // checking undefined as value can be 0 also in store
      if (alteratedBenefit.newSumInsured !== undefined) {
        sumInsuredErrorCollection[coverID] = ''
        if (Number(alteratedBenefit.newSumInsured) === 0) {
          sumInsuredErrorCollection[coverID] = fields.SumInsuredBlankError?.value
        } else {
          const cover = policy.covers.find(
            item =>
              item.type === alteratedBenefit.benefitCode &&
              String(item.benefitInstanceNo) === String(alteratedBenefit.benefitInstanceNo)
          )
          if (Number(alteratedBenefit.newSumInsured) > Number(cover.coverAmount)) {
            sumInsuredErrorCollection[coverID] = fields.SumInsuredIncreasedError?.value
          } else {
            const totalChildSumInsured = getTotalChildSumInsured(
              cover,
              policyStructure,
              policy.policyInstanceNo
            )
            if ((alteratedBenefit.newSumInsured || cover.coverAmount) < totalChildSumInsured) {
              sumInsuredErrorCollection[coverID] = fields.SumInsuredLessThanChildError?.value
            }
          }
        }
      }
    })
  })
  dispatch({
    type: SET_SUM_INSURED_ERRORS,
    payload: sumInsuredErrorCollection,
  })
}

export const resetSumInsuredErrors = () => ({
  type: RESET_SUM_INSURED_ERRORS,
})

export const resetAlterationsQuoteError = () => ({
  type: RESET_ALTERATIONS_QUOTE_ERROR,
})

export const setIsConfirmLeaveModal = (
  isConfirmLeaveModal: boolean,
  confirmLeaveModalRedirectURL: string,
  confirmLeaveModalItemID: string
) => ({
  type: SET_IS_CONFIRM_LEAVE_MODAL,
  payload: {
    isConfirmLeaveModal,
    confirmLeaveModalRedirectURL,
    confirmLeaveModalItemID,
  },
})

export const setIsAltsQuoteDowloaded = () => ({
  type: SET_IS_ALTS_QUOTE_DOWNLOADED,
})

export const setAltsQuoteURN = (quoteURN: string = '') => ({
  type: SET_IS_ALTS_QUOTE_URN,
  payload: quoteURN,
})

export const setIsAltsQuoteSubmitted = () => ({
  type: SET_IS_ALTS_QUOTE_SUBMITTED,
})

export const setIsSubmissionInProgress = (isSubmissionInProgress: boolean = true) => ({
  type: SET_IS_SUBMISSION_IN_PROGRESS,
  payload: isSubmissionInProgress,
})

export const setIsMaxDownloadLimitModal = (isMaxDownloadLimitModal: boolean) => ({
  type: SET_IS_MAX_DOWNLOAD_LIMIT_MODAL,
  payload: isMaxDownloadLimitModal,
})

export const setAltsQuoteSubmittedPolicies = () => ({
  type: SET_ALTS_QUOTE_SUBMITTED_POLICIES,
})
