import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'

// utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../../../utils/sitecoreUtils'

// selectors
import { getSelectedPoliciesData } from '../../../../../../selectors/alterationPolicies'

// types
import { SelectedPoliciesFields } from '../../../../../../types/components/AltsDeclaration'
import { SelectedPolicyData } from '../../../../../../types/components/InflationProofing'

// styles
import styles from './selectedPolicies.styles'

const PolicyList = styled('ul')(styles.policyList)

const SelectedPolicies = ({ fields }: { fields: SelectedPoliciesFields }) => {
  const policiesData: SelectedPolicyData[] = useSelector(getSelectedPoliciesData)

  return (
    <>
      {policiesData.length > 0 && (
        <PolicyList>
          {policiesData.map(policyData => (
            <li key={policyData.policyNumber}>
              <p>{fields.Policy?.value?.replace('##', policyData.policyNumber)} </p>
              <p>
                <span>{renderTextField(fields.LivesInsured)}</span>
                <span>{policyData.lifeInsured}</span>
              </p>
              <p>
                <span>{renderTextField(fields.PolicyOwners)}</span>
                <span>{policyData.policyOwners}</span>
              </p>
            </li>
          ))}
        </PolicyList>
      )}
    </>
  )
}

export default SelectedPolicies
