import React, { useCallback, useMemo, useEffect } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'

// components
import { Heading } from '@mlcl-digital/mlcl-design'
import Button from '@mlcl-digital/mlcl-design/lib/base/Button'
// @ts-expect-error file not in typescript
import ScrollToTop from '../../../atoms/ScrollToTop'
import PolicyCard from './components/PolicyCard'

// types
import {
  InflationProofingQuoteProps,
  PolicyData,
} from '../../../../types/components/InflationProofing'
import { Store } from '../../../../types/store'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../actions'

// utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import history from '../../../../utils/browserHistory'
import { createEvent } from '../../../../utils/telemetry'

// selectors
// @ts-expect-error file not in typescript
import { getAlterationType, getIsAltsPolicySelected } from '../../../../selectors/alterations'
import {
  getAlterationPoliciesCardsData,
  getIsSelectAllPoliciesAvailable,
} from '../../../../selectors/alterationPolicies'

// constants
import { ALTERATION_TYPES } from '../../../../constants/alterations'

// styles
import styles from './inflationProofingQuote.styles'

const Wrapper = styled('div')(styles.wrapper)
const ButtonContainer = styled('div')(styles.buttonContainer)
const CardContainer = styled('div')(styles.cardContainer)
const SectionHeading = styled(Heading)(styles.sectionHeading)
const SelectAllPoliciesBtn = styled(Button)(styles.selectAllPoliciesBtn)

const InflationProofingQuote = ({ fields }: InflationProofingQuoteProps) => {
  const dispatch = useDispatch()
  const alterationsState = useSelector((state: Store) => state.alterations)
  const alterationTypeForAnalytics: string = useSelector(getAlterationType())
  const isSelectAllPoliciesAvailable = useSelector(getIsSelectAllPoliciesAvailable)
  const policiesCardData: PolicyData[] = useSelector(
    getAlterationPoliciesCardsData(ALTERATION_TYPES.REJECT_CPI)
  )
  const isPolicySelected = useSelector(getIsAltsPolicySelected)

  useEffect(() => {
    const tagEvent = createEvent({
      GA: {
        category: alterationTypeForAnalytics,
        action: 'Reject CPI - quote page',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationTypeForAnalytics} - Reject CPI - quote page`,
        },
      },
    })
    tagEvent.end()
  }, [])

  const { policySelectionMap } = alterationsState

  // Need to memoize only once, value will not change
  const inforceEligiblePolicyIds = useMemo(
    () => policiesCardData.map(policy => policy.bancsPolicyNo),
    []
  )

  // eligiblePolicies data will remain same, therefore not adding in dependency array.
  const isAllPoliciesSelected = useMemo(
    () =>
      policiesCardData.every(inforcePolicy =>
        policySelectionMap ? policySelectionMap[inforcePolicy.bancsPolicyNo] : false
      ),
    [policySelectionMap]
  )

  const handleBackClick = useCallback(() => {
    // setting the flag so that back works
    dispatch(actionCreators.setAlterationTypeSelectedByUser(ALTERATION_TYPES.REJECT_CPI))
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    history.goBack()
  }, [])

  const handleMultiPolicySelection = useCallback(() => {
    if (isAllPoliciesSelected) {
      dispatch(actionCreators.deselectAllPolicies())
    } else {
      dispatch(actionCreators.selectListOfPolicies(inforceEligiblePolicyIds))
    }
  }, [isAllPoliciesSelected])

  const handleContinue = useCallback(() => {
    dispatch(actionCreators.timelineNextState())
    const tagEvent = createEvent({
      GA: {
        category: alterationTypeForAnalytics,
        action: 'Continue through to application - quote page',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationTypeForAnalytics} - Continue through to application - quote page`,
        },
      },
    })
    tagEvent.end()
  }, [alterationTypeForAnalytics])

  return (
    <ScrollToTop>
      <Wrapper>
        <p>{renderTextField(fields.Description, true)}</p>
        <SectionHeading size="xsmall" variant="h3">
          {renderTextField(fields.PolicySectionLabel)}
          {isSelectAllPoliciesAvailable && (
            <SelectAllPoliciesBtn variant="secondary" onClick={handleMultiPolicySelection}>
              {renderTextField(isAllPoliciesSelected ? fields.DeselectAllCTA : fields.SelectAllCTA)}
            </SelectAllPoliciesBtn>
          )}
        </SectionHeading>
        <CardContainer>
          {policiesCardData.length > 0 &&
            policiesCardData.map((policyData: PolicyData) => (
              <PolicyCard key={policyData.policyNumber} fields={fields} policyData={policyData} />
            ))}
        </CardContainer>
        <ButtonContainer>
          <Button variant="secondaryWithTheme" onClick={handleBackClick}>
            {renderTextField(fields.BackButton)}
          </Button>
          <Button onClick={handleContinue} disabled={!isPolicySelected}>
            {renderTextField(fields.ContinueButton)}
          </Button>
        </ButtonContainer>
      </Wrapper>
    </ScrollToTop>
  )
}

export default InflationProofingQuote
